@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import "~font-awesome/css/font-awesome.css";
/*!
 * Bootstrap  v5.3.2 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
@font-face {
  font-family: "Proxima Regular";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(assets/css/proximanova/ProximaNova-Regular.otf) format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Proxima Regular" !important;
  height: 100%;
}

body.app {
  background-color: #0F1228;
  color: #fff;
}

body.theme .main_left_sidebar, .promoting_tabs {
  background-color: #000000;
}

body.theme .promoting_tabs {
  background-color: #000000;
}

body.theme .footer_bg {
  background-color: #000000;
}

body.theme .promoting_tabs .nav-link.active {
  color: #ffffff;
}

body.light_mode_on.app {
  background-color: #fff;
  color: #212529;
}

body.theme .user_col {
  background-color: #000000;
}

body.theme .bottom_discription {
  background-color: #000000;
}

body.theme .title {
  color: #ffffff;
}

body.theme .promoting_tabs .nav-link span svg path {
  fill: #000000;
}

body.theme .subtitle {
  color: #ffffff;
}

body.theme .pay_id {
  color: #ffffff;
}

html {
  height: 100%;
}

body ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 0;
}

a, a:hover {
  text-decoration: none;
}

.loader-div {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(34, 34, 34, 0.85);
  pointer-events: bounding-box;
  width: 100%;
  height: 100%;
}
.loader-div .loader-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  text-align: center;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
  padding-top: 40px;
}
.loader-div .loader-text:before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}
.loader-div .loader-text:before {
  border-color: rgba(255, 255, 255, 0.15);
}
.loader-div .loader-text:after {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-animation: loader 0.6s linear;
  animation: loader 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0 0 0 1px transparent;
}
.loader-div .loader-text:after, .loader-div .loader-text:before {
  width: 2.28571429rem;
  height: 2.28571429rem;
  margin: 0 0 0 -1.14285714rem;
}
.loader-div .loader-text:after {
  border-color: #fff transparent transparent;
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.reponsible-footer {
  background-color: #000;
  text-align: center;
  padding: 15px 0 10px;
}

.responsible img {
  max-width: 300px;
}

.visit-link {
  color: #a7a7a7;
  font-size: 12px;
  font-weight: 500;
  padding: 0 15px;
}

.visit-link a {
  color: #a6de7f;
}

.lern_btn {
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  padding: 5px 15px;
  width: 104px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  margin: 0 2px;
  background-color: #5dae32;
  color: #fff;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.lern_btn:hover {
  background: #ffffff;
  color: #5dae32;
}

.left_sticky {
  transform: rotate(90deg);
  position: fixed;
  left: -93px;
  top: 255px;
  z-index: 8;
}

.right_sticky {
  transform: rotate(90deg);
  position: fixed;
  right: -93px;
  top: 255px;
  z-index: 8;
}

.left_link span {
  margin-bottom: 5px;
  position: relative;
  bottom: -4px;
}

.left_link span svg {
  transform: rotate(-90deg);
  width: 18px;
  height: 18px;
}

.app_title {
  padding: 0 10px;
}

.left_link {
  background-color: #5DAE32;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 228px;
}

.left_link:hover {
  color: #ffffff;
}

@media (min-width: 768px) {
  .col-md-3.left_bar {
    width: 330px;
  }

  .col-md-9.conetnt_col {
    width: calc(100% - 330px);
  }

  .hide-on-desktop {
    display: none;
  }
}
/* footer_css======================================= */
.page_footer {
  padding: 50px 35px 10px 35px;
  background-color: #101215;
}

.footer-logo {
  margin-bottom: 20px;
}

.footer-logo img {
  max-width: 100%;
  height: auto;
  max-height: 63px;
}

.sub-title_footer {
  font-size: 12px;
  font-weight: 500;
  color: #a7a7a7;
  margin-top: 5px;
  text-align: left;
}

.social_icon_listing ul {
  display: flex;
  align-items: center;
}

.social_icon_listing ul li a {
  font-size: 12px;
  font-weight: 600;
  color: #a6de7f;
  display: inline-flex;
  align-items: center;
}

.social_icon_listing ul li a i {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
}

.social_icon_listing {
  margin: 35px 0px;
  color: var(--white-text);
}

.social_icon_listing ul li {
  margin-right: 10px;
}

.page_footer_row .copy_right p {
  color: #a6de7f;
}

/* footer_css======================================= */
.right_sticky {
  transform: rotate(90deg);
  position: fixed;
  right: -93px;
  top: 255px;
  z-index: 9;
  background-color: #5DAE32;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 228px;
}

.help_content {
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  padding: 0 5px 0 5px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.socila_icon a {
  margin-left: 5px;
}

.socila_icon a:hover {
  color: #ffffff;
}

.socila_icon a svg {
  width: 20px;
  height: 20px;
  transform: rotate(270deg);
}

.socila_icon a svg path {
  fill: #ffffff;
}

.partner_section {
  padding: 10px 0 25px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 60%;
  background-color: #02091a;
}

.top_section {
  display: flex;
  justify-content: space-around;
  padding: 30px 35px 15px 35px;
}

.col_btn {
  display: flex;
  align-items: center;
  padding: 10px 25px;
  margin: 0 2px;
  cursor: pointer;
  position: relative;
  background-color: #296218;
  border-radius: 8px;
}

.top_section .col_btn:hover {
  background: linear-gradient(282.11deg, #296418 8.28%, #5dae32 48.64%);
}

.right-arrow {
  margin: 0 10px;
}

.right-arrow svg {
  width: 30px;
  height: 100%;
}

.right-arrow svg path {
  fill: #ffffff;
}

.icon {
  width: 37px;
  height: 37px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #1d4a27;
  border-radius: 50%;
}

.icon svg {
  width: 25px;
  height: 25px;
}

.icon svg path {
  fill: #ffffff;
}

.title {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 5px 0 10px;
}

.sports_section {
  background-color: #02091a;
  padding: 35px 0px;
}

.sports_col {
  border-radius: 10px;
  margin: 5px;
  padding: 6px 6px 6px;
  position: relative;
  border: 1px solid #5DAE32;
  background-color: #0a140c;
  display: flex;
  height: 100%;
}

.discription p {
  font-size: 11px;
  font-weight: 600;
  color: #d3d3d3;
}

.sports_content {
  padding: 0 5px;
}

.sports_content h5, .sports_content .h5 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0 5px 0;
}

.sports_image img {
  max-width: 200px;
  margin-right: 5px;
}

.wp-icon {
  position: fixed;
  bottom: 12%;
  z-index: 4;
  right: 20px;
  cursor: pointer;
}

.icon_bag {
  width: 55px;
  height: 55px;
  background-color: #000;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #33e675;
  border-top-right-radius: 8px;
  box-shadow: 0 0 9px -1px #33e675;
}

.icon_bag i {
  font-size: 30px;
  color: #33e675;
}

/* modal_css============================================================================= */
.modal_image {
  max-width: 300px;
}

.cross_btn {
  text-align: right;
  height: 0;
}

.login_page .modal-header {
  border: none;
  justify-content: center;
  padding: 0;
}

.login_page .modal-content {
  border-radius: 20px;
  padding: 8px 10px 0px;
  background-image: url(assets/images/login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: auto;
}

.login_page .modal-footer {
  border: none;
}

.login_page.modal-dialog {
  max-width: 700px;
}

.login_page .btn-close {
  border: none;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 12px;
  position: relative;
  z-index: 1;
}

.input_field {
  border-radius: 15px;
  border: 1px solid #e0efc8;
  background-color: #f7ffe8;
  padding: 5px 8px;
  position: relative;
  margin-top: 15px;
}
.input_field:first-child {
  margin-top: 0;
}

.login_page .modal-body {
  display: flex;
  justify-content: space-around;
  padding: 45px 0px 0px;
}

.input_label {
  text-align: left;
  top: 6px;
  left: 12px;
  color: #595959;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  position: absolute;
}

.form_control {
  padding: 20px 0 0 5px;
  width: 100%;
}

.mobile_bg img {
  max-width: 296px;
  margin-right: 0;
  width: 100%;
  margin-left: auto;
}

.modal-content .mobile_bg {
  width: 50%;
  text-align: center;
  padding: 0 15px 0 30px;
}

.modal-content .form {
  width: 50%;
  padding: 25px 45px 5px 30px;
}

.modal-backdrop.show {
  opacity: 0.3 !important;
}

.form_control {
  border: none;
  outline: none;
  background: none;
}

.alert_base span {
  color: #dd4b39;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.5;
  padding: 3px 0 0 10px;
}

.form_check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.check_link a {
  display: inline-block;
  font-size: 11px;
  color: #5DAE32;
  font-weight: 600;
  padding: 0px 0px 0px 20px;
}

.form_check .form-check-label {
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding: 6px 5px 5px 0px;
}

.sinup_btn {
  padding: 5px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(136.97deg, #ACFF80 3.04%, #369703 100%);
  border-radius: 16px;
  margin: 12px 0px;
  cursor: pointer;
}

button.sinup_btn:hover {
  background: linear-gradient(136.97deg, #a6de7f 3.04%, #328708);
}

input[type=number] {
  margin-right: 0 !important;
  width: 100% !important;
  padding: 0 !important;
}

.amount.input-field {
  padding-right: 0 !important;
}

input[type=number]::-webkit-inner-spin-button {
  cursor: pointer;
  height: 36px;
}

.member {
  text-align: center;
  color: #858585;
  font-size: 12px;
  font-weight: 500;
}

.ragister_link {
  display: inline-block;
  font-size: 11px;
  color: #5DAE32;
  font-weight: 600;
  cursor: pointer;
}

.ragister_link:hover {
  color: #5DAE32;
}

.social_media ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_media ul li {
  margin: 2px;
}

.social_media {
  margin-top: 10px;
}

.social_media ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #DAF5AE;
  border-radius: 50%;
}

.widraw {
  font-size: 22px;
  font-weight: 700;
  color: #5DAE32;
  margin: 10px 0 5px;
  line-height: 1.2;
  font-style: italic;
}

.input_field i {
  color: #d4d6d1;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
}

/* modal_css============================================================================= */
.left_sidebar {
  width: 265px;
  height: calc(100% - 70px);
  padding: 0 10px 0px 10px;
  position: fixed;
  left: 0;
  overflow: auto;
}

.game_page {
  height: calc(100% - 70px);
}

.right_sidebar {
  width: 335px;
  height: calc(100% - 70px);
  padding: 13px 10px 0px 10px;
  position: fixed;
  right: 0;
  top: 95px;
  overflow: auto;
}

.sidebar-div {
  display: block;
  position: relative;
}

.right_sidebar::-webkit-scrollbar, .left_sidebar::-webkit-scrollbar {
  display: none;
}

.promotion-tab ul.tabs {
  height: 40px;
  justify-content: center;
  display: flex;
  background-color: #DAF5AE;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.promotion-tab ul.tabs li {
  line-height: 40px;
  height: 40px;
  margin-top: 0;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}
.promotion-tab ul.tabs li a {
  font-size: 13px;
  font-weight: 500;
  color: #7ab95b;
  text-transform: capitalize;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  border-right: 1px solid #d1dfba;
}
.promotion-tab ul.tabs li a.active {
  background-color: transparent;
  font-weight: 600;
  color: #fff;
}
.promotion-tab ul.tabs li:last-child a {
  border-right: none;
}

.fade:not(.show) {
  display: none;
}

.btn-disable {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.select-wrapper input.select-dropdown {
  position: relative;
  cursor: pointer;
  background-color: initial;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  outline: none;
  height: 3rem;
  line-height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px;
  padding: 0;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 1;
}

.input-field {
  position: relative;
}

.row {
  margin-left: auto;
  margin-right: auto;
}

.col .row {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.row .col.m5, .row .col.m6 {
  margin-left: auto;
  left: auto;
  right: auto;
  flex: unset;
}

.submit-btn .can-btn {
  position: relative;
  right: 15%;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  max-width: 110px;
  height: 45px;
  display: inline-block;
  color: #5dae32;
  line-height: 36px;
  font-size: 14px;
  box-shadow: none;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s;
  margin: auto;
  background: transparent;
}
.submit-btn .can-btn:hover:before {
  display: none;
}
.submit-btn .can-btn:hover:after {
  display: none;
}

.row .col.s12 {
  width: 100%;
}

.row .col.m6 {
  width: 50%;
}

.p-0 {
  padding: 0 !important;
}

.input-field input {
  border: none;
  color: #000;
  display: block;
  width: 100%;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 0;
  margin-bottom: 0 !important;
  padding: 13px 0 0 5px;
  border-bottom: none;
  height: 45px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  transition: none;
  outline: none;
}

.btn, .btn-flat, .btn-large, .btn-small {
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn, .btn-floating, .btn-large, .btn-small, .card, .card-panel, .collapsible, .dropdown-content, .sidenav, .toast, .z-depth-1, nav {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-content {
  background-color: #fff;
  margin: 0;
  display: none;
  min-width: 100px;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  transform-origin: 0 0;
}

.dropdown-content {
  background-color: #fff;
}

.dropdown-content li {
  clear: both;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-height: 50px;
  line-height: 1.5rem;
  width: 100%;
  text-align: left;
}

.dropdown-content li > span, .wallet-list .dropdown-content li > a {
  padding: 5px 15px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: left !important;
  color: #000 !important;
  line-height: 2 !important;
}

.dropdown-content li > a, .dropdown-content li > span {
  font-size: 16px;
  color: #26a69a;
  display: block;
  line-height: 22px;
  padding: 14px 16px;
}

.dropdown-content li > span, .dropdown-content li > a {
  color: #2c2a2a;
  font-size: 12px;
  font-weight: 600;
}

.active_tabs li {
  line-height: 40px;
  height: 40px;
  margin-top: 0;
  display: flex;
  align-items: center;
}

.vartual_tab_link.promotion-tab {
  margin: 0;
}

.tabs li .active {
  font-weight: 600;
  color: #296418;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  height: 100%;
}

.tab_content {
  margin: 8px;
  padding: 0;
  display: block;
}

.vartual_tab_link ul {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
  background-color: #DAF5AE;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  justify-content: center;
}

.vartual_tab_link ul li .casino_title {
  padding: 5px 24px;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--promo-tab);
  font-weight: 500;
  cursor: pointer;
  position: relative;
  width: 33%;
  text-align: center;
  border-right: 1px solid #D1DFBA;
  height: 30px;
  line-height: 20px;
}

.vartual_tab_link ul li .casino_title.active {
  font-weight: 600;
  color: #1d4a27;
}

.vartual_tab_link ul li .casino_title.no-border {
  border-right: 1px solid transparent;
}

.tab_content img {
  max-width: 100%;
  height: auto;
}

.gamepage_wrapper {
  display: flex;
  background: #eaf0e1;
  padding: 0px 0px;
}

.mobile-nav_slide {
  width: 100%;
  padding-top: 15px;
  padding-left: 265px;
  padding-right: 335px;
}

.left_sidebar_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;
}

.menu_link {
  display: inline-block;
  color: #5DAE32;
  font-size: 14px;
  font-weight: 600;
}

.sports_link svg {
  width: 17px;
  height: 17px;
}

.sports_link svg path {
  fill: #000000;
}

.main_left_sidebar {
  border-radius: 16px;
  background-color: #ffffff;
  padding: 10px 0;
  height: 100%;
  width: 100%;
}

.burger_menu {
  display: none;
}

.burger_menu img {
  max-width: 30px;
  filter: invert(66%) sepia(94%) saturate(416%) hue-rotate(200deg) brightness(165%) contrast(151%);
}

.sidebar_accordian .accordion-item {
  border: none;
}

.sidebar_accordian .accordion-button {
  padding: 10px;
  text-transform: capitalize;
  align-items: center;
  position: relative;
  transition: all 0.5s;
  font-size: 14px;
  font-weight: 600;
  position: relative;
}

.sidebar_accordian .accordion-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: all 0.5s ease-out;
  z-index: -1;
  border-radius: 15px;
}

.sidebar_accordian .accordion-button:hover::before {
  transform: scaleX(1);
  transition-timing-function: linear;
}

.sidebar_accordian .accordion-button:hover {
  color: #000000;
}

.sidebar_accordian .accordion-button.collapsed:hover::after {
  color: #000000;
}

.bottom_tabs {
  display: none;
}

.sidebar_accordian .accordion-button span {
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.sidebar_accordian .accordion-button:focus {
  box-shadow: none;
  background: none;
}

.sidebar_accordian .accordion-button:not(.collapsed) {
  color: unset;
  background: rgba(174, 189, 203, 0.3);
  box-shadow: none;
  border-radius: 15px;
}

.sidebar_accordian .accordion-button::after {
  font-family: "Font Awesome 6 Free";
  background-image: none;
  content: "+";
  font-weight: 900;
  transition: transform 0.5s ease;
  color: #000000;
  background-image: unset;
  text-align: right;
}

.sidebar_accordian .accordion-button.collapsed::after {
  font-family: "Font Awesome 6 Free";
  background-image: none;
  content: "+";
  font-weight: 900;
  transition: transform 0.2s ease-in-out;
  color: #000000;
  background-image: unset;
  text-align: right;
}

.sidebar_accordian .accordion-button[aria-expanded=true]::after {
  transform: rotate(-45deg);
  background: unset;
}

.sidebar_accordian .accordion-body {
  padding: 0;
}

.collapsible li a {
  position: relative;
  padding: 0 0 0 10px;
  border-left: 2px #A6DE7F;
  background: 0 0;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #000000;
  width: 100%;
}

.collapsible li {
  margin-bottom: 2px;
}

.collapsible li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  background: #A6DE7F;
  height: 100%;
}

.market-data .market_row {
  cursor: pointer;
}

/* hide_css================ */
.casion_game.main {
  display: none;
}

.collapsible_header.main {
  display: none;
}

.market-data.main {
  display: none;
}

/* hide_css================ */
body a {
  text-decoration: none;
  cursor: pointer;
}

.mobile-nav_slide .slider .item img {
  border-radius: 12px;
  width: 100%;
}

.slider .owl-theme .owl-dots .owl-dot span {
  height: 8px;
  width: 8px;
}

.owl-theme .owl-dots .owl-dot.active span {
  background-color: #328708;
}

.slider .owl-theme .owl-nav.disabled + .owl-dots {
  margin: 0;
  position: absolute;
  right: 22px;
  bottom: 0;
}

.main_right_sidebar {
  border-radius: 16px;
  padding: 0;
  height: 100%;
  width: 330px;
  overflow: auto;
}
.main_right_sidebar .tab_content {
  background-color: #fff;
  margin: 0 0 10px;
  border-radius: 0 0 8px 8px;
}

.scrore_btn {
  position: relative;
}
.scrore_btn .suspend-cont {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.game_slider {
  background-color: #fff;
  margin: 0 0 10px;
  border-radius: 0 0 8px 8px;
}

.fancy-div .suspend-cont {
  position: absolute;
  right: 0;
  width: 130px;
  left: auto;
}

.fancy-div .scrore_btn {
  position: relative;
}

.profit {
  color: #72ac46 !important;
}

.loss {
  color: #cf3d35 !important;
}

.fancy-book.modal-dialog {
  max-width: 300px;
}
.fancy-book.modal-dialog .modal-header {
  background-color: #72ac46;
  padding: 5px 15px;
  height: 40px;
}
.fancy-book.modal-dialog .modal-header h2, .fancy-book.modal-dialog .modal-header .h2 {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 0;
}
.fancy-book.modal-dialog .modal-header .btn-close {
  color: #fff;
  background-color: #fff;
  border-radius: 50%;
  background-size: 10px;
  opacity: 1;
  height: 15px;
  width: 15px;
  position: absolute;
  right: 10px;
  margin: 0;
  top: 4px;
}
.fancy-book.modal-dialog .modal-body dl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  font-weight: bold;
}

.market-winLoss ul {
  padding-left: 7px !important;
}

.fancy-book-winLoss ul, .market-winLoss ul {
  padding-left: 20px;
}
.fancy-book-winLoss ul li, .market-winLoss ul li {
  display: inline-block;
  padding: 0;
}
.fancy-book-winLoss ul .loss a, .market-winLoss ul .loss a {
  font-weight: bold;
  color: #cf3d35 !important;
  font-size: 12px;
}
.fancy-book-winLoss ul .profit a, .market-winLoss ul .profit a {
  font-weight: bold;
  color: #72ac46 !important;
  font-size: 12px;
}
.fancy-book-winLoss .fa-line-chart, .market-winLoss .fa-line-chart {
  background-color: #71ab49;
  color: #fff;
  padding: 3px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.bet-list .top-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bet-list .top-list .bet-type {
  text-transform: uppercase;
  font-size: 14px;
}
.bet-list .top-list .avg-odd {
  color: #abda5d;
}
.bet-list .selection-text {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
.bet-list ul {
  text-align: center;
}
.bet-list ul li {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 0 5px;
}

body table.dataTable thead th, body table.dataTable thead td, body .dataTable tbody tr td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
body table.dataTable {
  border-collapse: collapse;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
body .btn {
  position: relative;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  max-width: 110px;
  float: right;
  height: 38px;
  display: block;
  color: #fff !important;
  line-height: 2;
  font-size: 12px;
  box-shadow: none;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  overflow: inherit;
  transition: all 0.2s;
}
body .submit-div {
  position: relative;
  float: left;
  width: 100%;
  padding-bottom: 15px;
}

.empty-betlist {
  text-align: center;
  padding: 50px 15px 40px 15px;
}
.empty-betlist img {
  width: 80px;
  margin: 5px;
}
.empty-betlist .title {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  margin: 10px 2px 7px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.empty-betlist .title2 {
  color: #000;
  font-size: 10px;
  font-weight: 500;
  margin: 5px 2px;
  text-align: center;
}

.main_right_sidebar::-webkit-scrollbar {
  overflow: hidden;
}

.game_thumb img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.gameitem_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.game_item {
  width: 50%;
  height: auto;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  float: left;
  border-radius: 20px;
  padding: 2px;
  transition: all 0.4s ease-out;
  border: 1px solid transparent;
}

.game_item:hover::before {
  opacity: 0.8;
  background-color: var(--secondary-color);
  background-image: linear-gradient(#3f3f4f 10%, #000 100%);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.645, 0.045);
}

.game_item:hover .play_btn {
  transform: translateY(10px);
  opacity: 1;
}

.play_btn {
  transition: 0.7s;
  position: absolute;
  top: 28%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 9;
  height: 16px;
  line-height: 1.1;
  opacity: 0;
}

.play_btn a {
  color: #ffffff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  height: 15px;
  line-height: 1.1;
  font-size: 10px;
  font-weight: 600;
  position: relative;
  z-index: 2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-decoration: none !important;
}

.game_thumb {
  margin-bottom: -5px;
}

.blink_new {
  top: 5px;
  right: 5px;
  background-color: red;
  color: #fff;
  font-weight: 700;
  padding: 3px;
  border-radius: 30px;
  font-size: 8px;
  float: right;
  line-height: 100% !important;
  box-shadow: 0 0 10px red;
  position: absolute;
}

.blink_text {
  -webkit-animation-name: blinker;
  animation-name: blinker;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.main_tabs {
  box-shadow: none;
  margin: 10px 0 15px 0;
  border-radius: 12px;
  border: 1px solid #32870842;
  display: block;
  background: #ffffff;
}

.scroll_slider {
  display: flex;
  flex-wrap: nowrap;
}

.slider_item a {
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  color: var(--all-event-title);
  background-color: var(--all-event-bg);
  display: block;
  padding-top: 5px;
  margin: 15px 7px 10px;
  position: relative;
  min-width: 80px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  border: 2px solid transparent;
  height: 60px;
  background-color: #ffffff;
}

.slider_item a:hover .sports_icon {
  transform: scale(1.2);
  transition: all 0.5s;
}

.slider_item .active {
  border: 2px solid #5DAE32;
}

.tag_live {
  position: absolute;
  top: -12px;
  right: -5px;
  min-width: 33px;
  height: 15px;
  border-radius: 3px;
  padding-right: 3px;
  color: #5DAE32;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  background-color: #dbe1e3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  display: flex;
}

.tag_live strong {
  flex: 1;
  height: 100%;
  margin-right: 3px;
  padding: 0 2px;
  border-radius: 3px 0 0 3px;
  background-color: #5DAE32;
  display: flex;
  color: #ffffff;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}

.icon_holder_small {
  width: 24px;
  height: 24px;
  margin: 2px auto auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.game_tabs.card {
  background: #191E38;
  border: none;
  border-radius: 0;
}

.item_details .title {
  padding: 5px 12px 0px 4px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  text-transform: capitalize;
  transition: all 0.2s;
  color: #000000;
}

.slider_item a:hover .title {
  transform: scale(1.2);
  transition: all 0.5s;
}

.collapse_bg {
  background-image: url(assets/images/collapse.png);
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  width: auto;
  min-width: 120px;
  height: 35px;
  text-align: center;
  top: -4px;
  display: inline-block;
}

.collapse_title {
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 12px 5px 10px;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.text {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.popular_games {
  border-radius: 10px;
  margin: 10px 0;
  display: block;
  background: #ffffff;
  width: 100%;
  cursor: default;
  position: relative;
  padding: 0 15px;
}

.popular_games .item {
  position: relative;
  overflow: hidden;
}

.sub_title {
  font-size: 9px;
}

.popular_games .item:hover::before {
  opacity: 0.8;
  background-color: var(--secondary-color);
  background-image: linear-gradient(#3f3f4f 10%, #000 100%);
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045);
  border-radius: 10px;
}

.play_btn h4, .play_btn .h4 {
  font-size: 11px;
  color: #ffffff;
}

.popular_games .item:hover .play_btn {
  transform: translateY(10px);
  opacity: 1;
}

.popular_games .owl-nav {
  margin: 0;
}

.popular_games .owl-prev,
.owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.popular_games .owl-prev {
  left: -18px;
}

.popular_games .owl-next {
  right: -18px;
}

.popular_games .owl-item img {
  border-radius: 10px;
}

.popular_games .owl-theme .owl-nav [class*=owl-]:hover {
  background: none;
  color: #FFF;
  text-decoration: none;
}

.popular_games .owl-prev span {
  font-size: 24px;
  color: #000000;
  font-weight: 600;
}

.game_slider {
  padding: 8px 0px;
}

.popular_games .owl-prev i {
  color: #000000;
}

.popular_games .owl-next i {
  color: #000000;
}

/* footer css======================================================== */
.footer_bg {
  padding: 25px 15px 5px 15px;
  border-radius: 12px;
  background-color: #ffffff;
  margin-top: 1%;
}

.footer_logo img {
  max-width: 222px;
}

.subtitle {
  font-size: 10px;
  font-weight: 500;
  color: #737070;
  margin-top: 18px;
  text-align: left;
}

.pay-details h4, .pay-details .h4 {
  color: #5DAE32;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 12px;
}

.pay-details .contact {
  color: #a7a7a7;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
}

.pay-id {
  font-size: 12px;
  font-weight: 500;
  color: #a7a7a7;
  margin-top: 5px;
  text-align: left;
}

.pay_id {
  font-size: 10px;
  font-weight: 600;
  color: #737070;
  margin-top: 5px;
  text-align: left;
}

.icons {
  text-align: center;
  margin: 25px 0 10px;
}

.icons a {
  margin: 0 5px;
}

.icons img {
  max-width: 30px;
}

.payment-img img {
  max-width: 220px;
}

.contact-details {
  margin: 25px 0px;
}

.contact-details h4, .contact-details .h4 {
  color: #5DAE32;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.contact-details h5, .contact-details .h5 {
  color: #737070;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0;
}

.contact-details h6, .contact-details .h6 {
  color: #737070;
  font-size: 14px;
  font-weight: 500;
}

.social-media ul {
  display: flex;
  align-items: center;
}

.social-media ul li a svg {
  width: 12px;
  height: 12px;
}

.social-media ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: #5DAE32;
  position: relative;
  z-index: 1;
}

.social-media ul li a path {
  color: #5DAE32;
}

.social-media ul li {
  margin-left: 2px;
}

.social-media ul li a span {
  background-color: rgba(93, 174, 50, 0.13);
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  margin: auto;
  border-radius: 50%;
  margin-right: 3px;
}

.social-media {
  margin: 35px 0px;
}

.copy_right p {
  font-size: 11px;
  color: #5DAE32;
  font-weight: 500;
  margin: 15px 0px;
}

/* footer css======================================================== */
.market_row {
  padding: 8px 10px;
  margin: 0px 0px 12px;
  border-radius: 10px;
}

.market_row {
  display: flex;
  align-items: center;
  width: 100%;
}

.badge-right ul {
  display: flex;
  align-items: center;
}

.badge-right ul li a i {
  font-size: 12px;
  color: #5dae32;
}

.badge-right ul li .badge-border {
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 2px 2px;
  font-weight: 600;
  cursor: pointer;
  font-size: 10px;
  color: #5dae32;
  border: 1px solid #5dae32;
  border-radius: 5px;
  position: relative;
}

.badge-right ul li span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 8px;
  color: #ffffff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #5dae32;
  transform: translate(50%, -50%);
}

.match_highlight {
  position: relative;
  width: 62px;
  height: 42px;
  border-radius: 5px;
  color: #000;
  text-align: center;
  font-weight: 600;
  background-color: #a6de7f;
  padding: 6px 1px;
  float: left;
  margin-right: 10px;
  text-transform: uppercase;
}
.match_highlight .time, .match_highlight .date {
  color: #000;
}

.match_highlight.inplay {
  background-color: #5dae32;
  color: #328708;
}
.match_highlight.inplay .time, .match_highlight.inplay .date {
  color: #fff;
}

.animate {
  overflow: hidden;
  position: relative;
  float: right;
  width: 73px;
  height: 40px;
  left: 7px;
  top: -4px;
}

.play {
  color: #ffffff;
  font-size: 9px;
  font-weight: 700;
  padding: 0;
  height: 39px;
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.in-play-light {
  display: flex;
  align-items: center;
  left: -4px;
  position: relative;
}

.icon-holder-small {
  width: 21px;
  top: 0;
  position: relative;
  height: 24px;
  margin: 0 auto auto;
  align-items: center;
  display: flex;
}

.time_date {
  display: block;
  top: 5px;
  position: relative;
}

.animate .flip-animation .date .animate .flip-animation .time {
  color: #ffffff;
}

.flip-animation {
  -webkit-animation: flip-1 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
  animation: flip-1 6s cubic-bezier(0.23, 1, 0.32, 1.2) infinite;
}

@keyframes flip-1 {
  0% {
    margin-top: -90px;
  }
  30% {
    margin-top: -60px;
  }
  55% {
    margin-top: -60px;
  }
  75% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -90px;
  }
}
.time {
  font-size: 9px;
  white-space: nowrap;
  text-align: center;
  display: block;
  font-weight: 700;
  color: #ffffff;
}

.icon-holder-small .sports-icon.inplay-light-icon {
  background-position: 0 -1360px;
}

.icon-holder-small .sports-icon {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url(assets/images/sports-icons-hattrickk.png);
  width: 24px;
  height: 20px;
  background-position: 0 -900px;
  background-size: 24px auto;
}

.date {
  font-size: 9px;
  white-space: nowrap;
  text-align: center;
  display: block;
  font-weight: 700;
  color: #ffffff;
}

.match_highlight.inplay::before, .match_highlight.inplay::after {
  animation-delay: -4s;
  content: "";
  z-index: 0;
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  border-radius: 7px;
  -webkit-animation: clipMe 8s linear infinite;
  animation: clipMe 8s linear infinite;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.match_highlight.in-play::before {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
}

@-webkit-keyframes clipMe {
  0%, 100% {
    clip: rect(0, 75px, 2px, 0);
  }
  25% {
    clip: rect(0, 2px, 75px, 0);
  }
  50% {
    clip: rect(45px, 75px, 75px, 0);
  }
  75% {
    clip: rect(0, 75px, 75px, 45px);
  }
}
@keyframes clipMe {
  0%, 100% {
    clip: rect(0, 75px, 2px, 0);
  }
  25% {
    clip: rect(0, 2px, 75px, 0);
  }
  50% {
    clip: rect(45px, 75px, 75px, 0);
  }
  75% {
    clip: rect(0, 75px, 75px, 45px);
  }
}
.basic_row {
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
}

.sports_slash {
  position: absolute;
  top: 0;
  left: 50%;
}

.sports_slash svg {
  width: 15px;
  height: 15px;
}

.sports_slash svg path {
  fill: #a6de7f;
}

.date_high_light h4, .date_high_light .h4 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.date_high_light h6, .date_high_light .h6 {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.table_row {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.match_table {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-left: 10px;
}

.match_item {
  min-width: 84px;
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.match_no {
  background-color: #7BF1F9;
  color: #000000;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-size: 12px;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.color {
  background-color: #f4bafb;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #000000;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-size: 12px;
  font-weight: 600;
}

.date-time_menu {
  position: relative;
  width: 50px;
  min-width: 50px;
  height: 35px;
  border-radius: 5px;
  color: #000;
  text-align: center;
  font-weight: 600;
  border: 1px solid #eee;
  padding: 2px 0;
  float: left;
  margin-right: 2px;
  text-transform: uppercase;
}

.date_menu {
  font-size: 9px;
  line-height: 15px;
  white-space: nowrap;
  text-align: center;
  display: block;
  font-weight: 600;
  color: #e6e6e6;
}

.time_menu {
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
  text-align: center;
  display: block;
  font-weight: 700;
  color: #e6e6e6;
}

.game_name {
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  padding: 2px 5px;
  line-height: 1.2;
}

.versis-img svg {
  width: 15px;
  height: 15px;
}

.versis-img svg path {
  fill: #a6de7f;
}

.sports-icon img {
  width: 12px;
}

.upcoming-event {
  display: none;
}

.upcoming-event.base_form {
  display: block;
}

.upcoming-event ul li a {
  height: auto;
  line-height: 1;
  color: #000000;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  margin: 3px 0;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  transition: all 0.35s ease-in-out;
}

.upcoming-event ul li a:hover {
  transform: translateX(5px);
}

.upcoming-event ul li .active {
  background-color: #68b041;
}

.versis-img {
  text-align: left;
}

.hide_menu.base {
  display: none;
}

/* casion_css======================================================================= */
.casion_game {
  background-color: #ffffff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 12px;
  padding: 6px 9px 8px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.all_casion_game {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: scroll;
}

.casion_tabs ul {
  display: flex;
  align-items: center;
}

.casion_tabs ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin: 10px 3px;
  min-width: 100px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100px;
  height: 38px;
  border-radius: 8px;
  color: #2e2e31;
  font-weight: 600;
}

.casion_tabs ul li a:hover {
  border: 1px solid #5dae32;
}

.tabs_main.base_info {
  display: none;
}

.game_provider ul {
  display: flex;
  align-items: center;
}

.arrows {
  display: inline-grid;
  position: absolute;
  right: 6px;
}

.arrows i {
  font-size: 10px;
}

.all_providers {
  width: auto;
  min-width: 110px;
  margin: 0 5px;
  position: relative;
}

/* select_css=============================== */
.game_provider {
  display: flex;
  align-items: center;
}

.game_provider .form-select {
  font-size: 12px;
  box-shadow: none;
  width: 105px;
  background: none;
  border: 1px solid #eee;
  height: 40px;
  padding: 10px 15px 10px 5px;
  margin: 0px 3px;
  color: #a7a7a7;
  font-weight: 500;
}

/* select_css=============================== */
.game_provider ul li a {
  font-size: 12px;
  color: #a7a7a7;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #eee;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 26px;
}

.thumb {
  overflow: hidden;
}

.thumb img {
  max-width: 100%;
  height: 113px;
  width: 100%;
}

.port-inner {
  width: 25%;
  height: auto;
  cursor: pointer;
  position: relative;
  padding: 5px;
  border: 1px solid gold;
}

.portfolio_row {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.port_content a {
  font-size: 14px;
  color: #000000;
  text-transform: uppercase;
}

.port_content a:hover {
  color: #296418;
}

.casion_tabs ul li .active {
  border: 1px solid #5dae32 !important;
  background: #5dae32 !important;
  color: #fff !important;
}

.port-inner .thumb {
  border-radius: 8px;
}

.sub-title {
  color: #a7a7a7;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 400;
}

/* casion_css======================================================================= */
.scroll_top {
  position: fixed;
  right: 30px;
  bottom: 200px;
  animation: puls 3s linear infinite;
  border-radius: 50%;
  z-index: 1;
}

.scroll_link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #5dae32;
  border-radius: 50%;
  color: #ffffff;
}

.scroll_link:hover {
  color: #ffffff;
}

@keyframes puls {
  0% {
    box-shadow: 0 0 0 0 #000;
  }
  40% {
    box-shadow: 0 0 0 20px rgba(231, 75, 60, 0);
  }
  80% {
    box-shadow: 0 0 0 20px rgba(231, 75, 60, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(231, 75, 60, 0);
  }
}
/* reporting_page_css======================================================================= */
.news_feed {
  position: relative;
  width: 100%;
  padding-right: 5px;
  background-color: #262D50;
  color: #fff;
  padding-left: 5px;
  height: 28px;
  line-height: 28px;
  display: flex;
  font-size: 13px;
  font-weight: 600;
}

.bottom-header .news_feed {
  padding-left: 15px;
  z-index: 99;
}

.news_feed .title {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-right: 5px;
  width: 70px;
  display: none;
}

.light_mode_on .news_feed {
  background: #fff;
  color: #000;
}
.light_mode_on .news_feed .title {
  color: #000;
}

.svg-icon svg {
  width: 18px;
  height: 18px;
}

.setting-dropdown svg {
  width: 23px;
  height: 23px;
}

.mobile-nav_slide.nav_slide_mobile {
  width: calc(100% - 255px);
  margin: 0 0 0 255px;
}

.promoting_tabs {
  background-color: #0F1228;
  padding: 0 15px 5px 0 !important;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.main_left_sidebar.sidebar {
  margin-top: 24px;
}

.promoting_tabs .nav-link {
  width: 125px;
  height: 45px;
  padding: 5px 10px;
  margin: 0 10px 10px;
  position: relative;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #262D50 !important;
  font-size: 12px;
  color: #fff;
  font-weight: 600;
}

.promoting_tabs .nav-link span {
  font-size: 20px;
  margin-right: 5px;
}

.promoting_tabs .nav-link span svg {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.promoting_tabs .nav-link span svg path {
  fill: #fff;
}

.promoting_tabs .nav-link:hover {
  color: #fff;
}

.promoting_tabs .nav-link.active {
  background: none;
  color: #fff !important;
  background-image: linear-gradient(to top, #05AD34, #8EF50B) !important;
}

.user_btn {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  background-color: #5dae32;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
}

.user_btn input {
  opacity: 0;
  position: absolute;
}

.user_col {
  border-radius: 15px;
  background: #191E38;
  padding: 12px 18px 16px;
  height: 100%;
}

.user-profile-details h6, .user-profile-details .h6 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #5dae32;
  line-height: 1.5;
  margin-top: 5px;
}

.top-content span {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.bottom-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.bottom_discription .title {
  color: #ddd;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0 5px;
}

.bottom_discription .discription {
  color: #5dae32;
  font-size: 32px;
  font-weight: 500;
  margin: 15px 0;
}

.bottom_discription {
  width: 33.33%;
  background-color: #272e4e;
  text-align: center;
  margin: 10px;
  border-radius: 15px;
}

.v-status {
  color: #03a41d;
  margin: 0 0 0 3px;
  font-size: 14px;
  font-weight: 600;
}

.not-verified {
  color: #d9534f;
}

.number {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
}

.number svg {
  margin-right: 3px;
  width: 14px;
  height: 14px;
}

.number svg path {
  fill: #ccc;
}

.user-right {
  margin-left: 24px;
}

.bottom_content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0px;
}

.gender {
  margin-top: 10px;
  cursor: pointer;
}

.bonus-subscription h4, .bonus-subscription .h4 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0;
}

.turnover {
  text-align: center;
}

.bonus-subscription span {
  color: #ddd;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  display: block;
}

.turn_over_col {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.total-turnover {
  color: #5dae32;
  font-size: 20px;
  font-weight: 600;
}

.turnover-date {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin: 10px 5px 5px 5px;
}

.odds_section.odds_head button {
  line-height: 32px;
  height: 32px;
}

.minMax-limit {
  display: flex;
  justify-content: left;
  align-items: center;
}
.minMax-limit p {
  margin: 0;
  font-size: 12px;
  margin-right: 10px;
  color: #ccc;
}

.users-details {
  margin: 15px 0px 20px;
}

.account_title .card-title {
  display: block;
  margin-top: 0;
  font-weight: 600;
  padding-left: 0;
  padding-top: 5px;
  font-size: 18px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.reporting_tabs .account_title {
  display: flex;
  justify-content: space-between;
}
.reporting_tabs .date_picker {
  background-color: #262D50;
  border-radius: 6px 0 6px 6px;
  border: 1px solid #535776;
  padding: 14px 10px 0 10px;
  margin-top: -2px;
}
.reporting_tabs button.filter-btn {
  border-width: 1px 1px 0;
  border-color: #535776;
  border-style: solid;
  border-radius: 6px 6px 0 0;
  padding: 0 15px;
  width: 120px;
  line-height: 40px;
  background-color: #262D50;
  color: #01BD35;
  font-size: 16px;
  text-transform: capitalize;
  box-shadow: none;
}
.reporting_tabs .dataTables_length {
  margin-bottom: 15px;
}
.reporting_tabs table.dataTable {
  border: 1px solid #535776 !important;
  width: 100% !important;
}
.reporting_tabs table.dataTable thead tr th {
  background-color: #191E38;
  color: #ccc;
  font-size: 13px;
  padding: 0 10px;
  line-height: 35px;
  border-bottom: none;
  white-space: nowrap;
}
.reporting_tabs table.dataTable tbody tr td {
  border-bottom: 1px solid #0F1228;
  padding: 0 10px;
  line-height: 40px;
  color: #fff;
  font-size: 13px;
  white-space: nowrap;
}

.modal-dialog.modal-large {
  max-width: 980px;
}

.market .card-header {
  padding: 10px;
}

.market .card-header span img {
  margin-left: 5px;
  cursor: pointer;
}

.kyc-staus-box {
  padding: 5px 5px 5px 0;
  height: auto;
  min-height: 40px;
  line-height: 40px;
  border-radius: 12px;
  width: 100%;
  margin-left: 0;
}

.kyc-title {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.status {
  padding: 5px 8px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px;
}

.pending {
  background-color: #e13a3a;
  color: #fff;
}

.document-details .input_field {
  padding: 2px 8px !important;
  margin: 0;
  background-color: var(--input-color);
  border: 1px solid var(--input-border);
  border-radius: 12px;
  max-width: 90%;
}

.bonus {
  text-align: center;
  margin-top: 10px;
}

.right_aside .user-menus .user_list {
  display: flex;
  align-items: center;
}

.user-menus ul li {
  margin-right: 13px;
}

.hide_on a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ffffff;
}

.hide_on svg {
  width: 23px;
  height: 23px;
}

.hide_on path {
  fill: #5dae32;
}

.deposit a {
  height: 40px;
  width: 90px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  -webkit-animation-name: blinker2;
  animation-name: blinker2;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition: 0.3s;
  position: relative;
}

.deposit a:hover svg path {
  stroke: #ffffff;
}

.deposit a:hover {
  background-color: #328708;
  color: #ffffff;
}

@keyframes blinker2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
.deposit a svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.deposit a svg rect {
  stroke: #5dae31;
}

.no-wallet a {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 35px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 7px;
  background-color: #7ab95b;
  border-radius: 5px;
  position: relative;
}

.no-wallet a span {
  color: #ffffff;
}

.bal-title {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #3d930f;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.avatar a img {
  width: 25px;
  height: 25px;
}

.avatar a i {
  margin-left: 2px;
  font-size: 10px;
  color: #fff;
}

.user_name .marquee {
  position: relative;
  overflow: hidden;
  height: 20px;
  line-height: 20px;
}

.user_name {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.user_name .marquee .marquee-inner {
  display: inline-block;
  width: 40px;
  height: 20px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  -webkit-animation: marquee_reverse 5s linear infinite;
  animation: marquee_reverse 5s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.user_name:hover .marquee-inner {
  text-overflow: inherit;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation: marquee 5s linear infinite;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-80%);
  }
}
.bankList-table {
  padding-left: 0 !important;
}

#open .ng-select-container .ng-placeholder {
  line-height: 41px;
}

.data_table label {
  color: #000;
  font-size: 12px;
  padding-left: 0;
  font-weight: 600;
}

.reporting_tabs .nav-pills .nav-item:first-child .nav-link {
  margin-left: 0;
}

.data_table .dataTables_wrapper .dataTables_filter input {
  width: auto;
  margin-left: 5px;
  height: 30px;
  line-height: 30px;
  border: 1px solid transparent;
  background: rgba(93, 174, 50, 0.2);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  padding-left: 10px;
  color: #000000;
  text-transform: capitalize;
  outline: none;
}

.data_table table.dataTable.display > tbody > tr.odd > * {
  box-shadow: none;
}

.data_table .dataTables_info {
  color: #000;
  font-size: 12px;
  font-weight: 600;
}

.data_table .dataTables_paginate {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.data_table th {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 10px 18px;
}

.data_table td {
  padding: 8px 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.balance {
  color: #e63f6d;
}

.credit {
  color: #03a41d;
}

.discription {
  color: #999999;
  font-weight: 700;
}

.data_table .dataTables_empty {
  color: #999999;
  font-weight: 600;
  font-size: 12px;
}

.select-wrapper {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

.select-dropdown.dropdown-trigger {
  height: 20px;
}

.select-dropdown {
  border: none;
  padding: 0;
  font-size: 11px;
  font-weight: 600;
  color: #000000;
  height: 30px;
  line-height: 30px;
  margin-bottom: 3px;
  width: 100%;
  outline: none;
  background-color: #f7ffe8;
}

.select-dropdown::placeholder {
  color: #d3d1d1;
}

input::placeholder {
  color: #d3d1d1 !important;
}

.invalid .helper-text {
  min-height: 12px;
  font-size: 11px;
  line-height: 15px;
  float: left;
  width: 100%;
  text-transform: lowercase;
  color: #dd4b39;
  font-weight: 600;
}

.kyc-dropdown.dropdown-kyc {
  margin: 0;
}

.upload-id {
  height: 100%;
  position: relative;
  width: 80%;
}

.upload-id .btn {
  height: 100%;
  line-height: 40px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #f7ffe8;
  box-shadow: none;
  text-transform: capitalize;
  border: 1px solid #e0efc8;
  display: grid;
  line-height: 1.5;
}

.upload-id .btn a {
  position: relative;
  background-color: #eaeaea;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  top: 25px;
}

.upload-id .btn a i {
  font-size: 16px;
  color: #000;
  font-weight: 900;
}

.upload-id .btn .file_input {
  border: none;
  color: #000000;
  display: block;
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 0;
  margin-bottom: 0;
  padding: 13px 0 0 5px;
  border-bottom: none;
  height: 25px;
  box-shadow: none;
  transition: none;
  height: auto;
}

.upload-id .btn .file_input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.submit-btn {
  text-align: right;
  padding: 22px 0px;
}

.submit-btn a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 45px;
  font-size: 14px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  transition: all 0.35s;
}

.submit-btn a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.submit-btn a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #a6de7f;
  transition: all 0.3s;
  z-index: -1;
}

.submit-btn a:hover::before {
  width: 100%;
}

.dt-buttons button {
  outline: none;
  background-color: #dfefd6;
  box-shadow: none;
  border: 1px solid transparent;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  padding: 3px 10px;
  margin-left: 5px;
  transition: 0.3s;
}

.dt-buttons button:hover {
  color: #ffffff;
  background: #5dae32;
  transition: all ease-in 0.3s;
}

.date_picker {
  display: flex;
  flex-wrap: wrap;
}

header {
  display: block;
  width: 100%;
  height: 70px;
}

.open_bet_row {
  margin-bottom: 10px;
}

.deposit_modal .modal-header {
  justify-content: flex-end;
  border: none;
  margin: 0;
  padding: 9px 11px;
}

.deposit_modal .modal-header a {
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}

.deposit_modal .modal-body {
  text-align: center;
  padding: 10px;
}

.deposit_modal .modal-body img {
  width: 130px;
  margin-bottom: 10px;
}

.deposit_modal .modal-content {
  border-radius: 20px;
  background: #fafafa;
}

.deposit_modal .modal-dialog {
  max-width: 400px;
}

.update button {
  position: relative;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  max-width: 180px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 33px;
  display: block;
  background-color: #03a41d;
  color: #ffffff;
  line-height: 2;
  font-size: 12px;
  box-shadow: none;
  font-weight: 600;
  border-radius: 30px;
  text-transform: uppercase;
  text-align: center;
  overflow: inherit;
  transition: all 0.2s;
  outline: none;
  border: none;
}

.alert_base.base-alert {
  text-align: left;
}

.deposit_modal .input_field .form_control::placeholder {
  color: #d8dad5;
  font-size: 12px;
  font-weight: 600;
}

.deposit_modal .input_field {
  background-color: #f7ffe8;
}

.base .setting_dropdown {
  width: 223px;
  min-width: 195px;
  border-radius: 12px;
  box-shadow: 0px 1px 5px #e5e9ec;
  background-color: #fff;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  padding: 9px;
  transition: transform 0.5s ease;
  position: absolute;
  right: 4px;
  z-index: 1;
  display: none;
  top: 71px;
}

.user-menus ul.right {
  display: block;
}

.setting_dropdown .arrow {
  right: 118px;
  width: 0.8rem;
  height: 1rem;
  position: absolute;
  top: -18px;
}

.setting_dropdown .arrow::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #ffffff;
}

.setting_dropdown .arrow::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #ffffff;
}

.toggle-switch .title {
  display: block;
  color: #5dae32;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  margin: 0;
}

.toggle-icon {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  margin: auto;
  margin: 8px 0px;
  width: 100%;
}

.light-icon svg, .dark-icon svg {
  width: 15px;
  height: 15px;
}

.light-icon svg path {
  fill: #5dae32;
}

.light-icon, .dark-icon {
  position: relative;
  color: #5c6060;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
  cursor: pointer;
}

switch .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input[type=checkbox] {
  margin-top: 0;
  vertical-align: middle;
}

.switch .form-switch .form-check-input {
  width: 35px;
}

.dropdown-content li, .avatar .avtar-dropdown li {
  padding: 2px 8px;
  color: #3d930f;
  height: 28px;
  line-height: 25px;
  border-radius: 5px;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-content, .avatar .avtar-dropdow {
  min-width: 170px;
  position: absolute;
  border-radius: 5px;
  width: auto;
  height: auto;
  top: 59px;
  -webkit-backdrop-filter: blur(82px);
  backdrop-filter: blur(82px);
  background-color: #191E38;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  transform: translate(-37px, -6px);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.avatar .avtar-dropdow {
  opacity: 1;
  visibility: visible;
  min-width: 230px;
}
.avatar .avtar-dropdow li {
  padding: 2px 0;
}

.avatar .avtar-dropdow li a {
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  text-align: left !important;
  color: #AEBDCB !important;
  line-height: 40px !important;
  border: none !important;
  border-radius: 0 !important;
  display: flex !important;
  height: 30px !important;
}
.avatar .avtar-dropdow li a svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.avatar .avtar-dropdow li a:hover {
  background-color: #262D50 !important;
  color: #AEBDCB !important;
  transition: ease-in 0.2s;
}

.avatar .avtar-dropdow {
  right: 0 !important;
  transform: unset !important;
  left: auto !important;
  top: 55px !important;
}

.no-wallet:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
  transform: scale(1.1);
}

.nav_links.links_nav li {
  margin-right: 2px;
}

.avatar .profile_list {
  position: absolute;
  min-width: 230px;
  background: #e0ecd9;
  transform: translate(-184px, 1px);
  border-radius: 5px;
  padding: 0px 0px 10px;
  display: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.profile_list li a {
  display: inline-block;
  font-size: 12px;
  color: #5dae32;
  width: 100%;
  font-weight: 600;
  padding: 8px 16px;
  transition: 0.3s;
}

.user-menus .profile_list li {
  margin-right: 0;
}

.profile_list li a:hover {
  background-color: #ffffff;
}

.user-menus .profile_list li svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.user-menus .profile_list li path {
  fill: #5dae32;
}

.user-menus .profile_list .log-out path {
  fill: none;
  stroke: #5dae32;
}

.click_cross {
  cursor: pointer;
}

.hide_on .click_collapse {
  position: absolute;
  width: 230px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 5px, rgba(0, 0, 0, 0.23) 0 2px 5px;
  z-index: 1;
  background-color: #dff2d4;
  transform: translate(-145px, 10px);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: none;
}

.click_collapse h5, .click_collapse .h5 {
  color: #5dae32;
  font-size: 14px;
  padding: 4px 2px 5px 5px;
}

.click_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.click_btn {
  display: none;
}

.click_btn ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.hide_on ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 79px;
  height: 32px;
  background-color: #a6de7f;
  border-radius: 4px;
  font-size: 12px;
  color: #296218;
  transition: 0.3s;
}

.hide_on ul li .active {
  background-color: #5dae33;
  color: #ffffff;
}

.hide_on ul li a:hover {
  background-color: #5dae33;
  color: #ffffff;
}

.hide_on ul li {
  margin: 3px 3px;
}

.add_coustem {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px;
  cursor: pointer;
  margin-top: 2px;
  color: #5dae32;
  text-align: center;
}

.on_click {
  background-color: #ffffff;
  font-size: 12px;
  width: 100px;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: 0;
  padding: 6px 3px 7px;
  transform: translate(-33px, 2px);
  color: #03a41d;
  font-weight: 600;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}

.hide_on:hover .on_click {
  visibility: visible;
  opacity: 1;
}

/* reporting_page_css======================================================================= */
.detail_page {
  background-color: #eaf0e1;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 100%;
  position: relative;
  padding-left: 265px;
  padding-right: 335px;
  padding-top: 15px;
}

.selectbet-header {
  background-color: #262D50;
  position: relative;
  border-radius: 12px;
  line-height: 1.5;
  margin: 10px 0 10px 0;
}

.countdownHolder {
  background-image: url(assets/images/timer-bg.png);
  font-size: 12px;
  font-weight: 500;
  position: relative;
  top: -23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
}

.coundown {
  display: flex;
  align-items: center;
}

.coundown .coundown_number {
  padding: 0 4px;
}

.coundown_number span {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid rgba(74, 191, 128, 0.2);
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}

.coundown_number h6, .coundown_number .h6 {
  color: #ffffff;
  font-size: 10px;
  margin: 3px 0px;
}

.text-counter {
  text-align: center;
}

.addmarket-icon ul {
  display: flex;
}

.addmarket-icon ul li a img {
  max-width: 20px;
}

.addmarket-icon ul li {
  margin-left: 10px;
}

.addmarket-icon {
  position: absolute;
  right: 5px;
  top: 6px;
  padding: 0 5px;
}

.teams-name {
  position: relative;
  top: -15px;
}

.date-details {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 3.50769px 3.50769px rgba(0, 0, 0, 0.25);
}

.date-title {
  color: #ccc;
  text-transform: uppercase;
  margin-right: 16px;
}

.bottom-row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding: 20px 0px;
}

.left-team-title {
  display: flex;
  align-items: center;
}

.right-team-title {
  display: flex;
  align-items: center;
}

.vs-image-content {
  position: relative;
  width: 130px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #daf5ae;
  margin: 0 30px;
  border-radius: 70px;
  box-shadow: -12px 12px 24px #C9E897, 12px -12px 24px #DDFCAA, -25px -12px 29px -16px #C5EC84, -11px -2px 31px #F3FFDE, inset 1px 1px 2px #D7F4A7, inset -1px -1px 2px #DDFCA9;
}

.left-team-title .team-bg {
  background-color: #a6d657;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
}

.right-team-title .team-bg {
  background-color: #a6d657;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
}

.vs-image-content svg {
  width: 50px;
  height: 50px;
}

.vs-image-content svg path {
  fill: #1d4a27;
}

.title {
  font-size: 30px;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}

.team-title-full {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  line-height: 17px;
  color: #1d4a27;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-category {
  padding: 0px 0px 30px;
}

.select-category .nav-tabs {
  border: none;
  margin-bottom: 10px;
}

.select-category .nav-link {
  color: #000000;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 8px;
  box-shadow: -3.3913px 3.3913px 6.78261px rgba(237, 237, 237, 0.2), 3.3913px -3.3913px 6.78261px rgba(237, 237, 237, 0.2), -3.3913px -3.3913px 6.78261px rgba(255, 255, 255, 0.9), 3.3913px 3.3913px 8.47826px rgba(237, 237, 237, 0.9), inset 0.847826px 0.847826px 1.69565px rgba(255, 255, 255, 0.3), inset -0.847826px -0.847826px 1.69565px rgba(237, 237, 237, 0.5);
  transition: background 0.8s;
}

.semiheader_warpper .toggle_icon {
  margin-right: 10px;
}

.text-right {
  text-align: right !important;
  padding-right: 10px;
}

.select-category .nav-link:hover {
  border: 1px solid #5dae32 !important;
  color: #000;
}

.select-category .nav-tabs .nav-link {
  height: 40px;
  min-width: 70px;
  padding: 2px 7px;
  border-radius: 8px;
  line-height: 34px;
  background: #fff;
}

.select-category .nav-tabs .nav-link.active {
  border: 1px solid #5dae32;
}

.select-category ul li {
  margin: 0px 4px;
}

.odd-title-bg {
  background-color: #262D50;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  padding: 0px 6px 0px;
}

.score-event-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 2.2;
  color: #fff;
  text-transform: capitalize;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.scores-event::after {
  content: "";
  position: absolute;
  top: 0;
  right: -19.3px;
  border-left: 10px solid #262D50;
  border-top: 15px solid #262D50;
  border-right: 10px solid transparent;
  border-bottom: 14px solid transparent;
}

.scores-event i {
  color: #03a41d;
  font-size: 10px;
  margin-right: 3px;
  width: 15px;
  position: relative;
  text-align: center;
}

.bet-semiheader {
  display: flex;
  align-items: end;
}

.smalltext {
  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  color: #03a41d;
  display: inline-block;
}

.m-info {
  margin-left: 20px;
}

.semiheader_warpper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-bottom: 0;
  background-color: #fff;
}

.detail-inner {
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.71);
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  line-height: 0;
}

.toggle_icon a i {
  color: #6fb749;
}

.team_row {
  margin: 2px 0px;
}

.scrore_btn ul {
  display: flex;
  align-items: center;
}

.scrore_btn ul.justify-content-right li {
  width: 60px;
}

.scrore_btn ul li a span {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.scrore_btn ul li a {
  padding: 7px 5px 5px;
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  line-height: 19px;
  min-height: 41px;
}

.justify-content-right {
  justify-content: right !important;
}

a.tab_semi, a.tab_dark {
  color: #000 !important;
}

.l-pink {
  background-color: #fce8ff;
  color: #b476bb !important;
}

.scrore_btn ul li a h5, .scrore_btn ul li a .h5 {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 10px;
}

.scrore_btn ul li a span {
  line-height: 10px;
}

.scrore_btn ul li {
  margin: 0px 1px;
  display: inline-flex;
  width: calc(100% / 6);
}
.scrore_btn ul li div {
  width: 100%;
}
.scrore_btn ul li div a {
  width: 100%;
  display: block;
}

.team_name {
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  color: #111215;
  line-height: 1;
  padding: 7px 11px;
  letter-spacing: 0.67px;
}

.tab_blue {
  background-color: #e0fbfd;
  color: #54999d !important;
}

.tab_dark {
  background-color: #f4bafb;
  color: #b476bb;
}

.tab_semi {
  background-color: #7bf1f9;
  color: #14585c;
}

.team_row {
  background-color: #f7f7f7;
}

.tab_row.show {
  display: none;
}

.toggle_icon a i.trigger {
  transform: rotate(-180deg);
}

.bet_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 9px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #dfdfdf;
  color: #9f9f9f;
  font-size: 13px;
}

.suspend-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1d4a27;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  line-height: 18px;
  border-radius: 8px;
  background-color: rgba(236, 250, 230, 0.78);
  backdrop-filter: blur(20px);
  height: 43px;
}

.input {
  font-weight: 700;
  font-size: 15px;
  padding: 0 12px;
}

.odd-info .dot {
  font-size: 14px;
  font-weight: 600;
}

.bet_collapse {
  margin: 6px 0px;
}

.cross_icon {
  cursor: pointer;
}

.bet_collapse.show_collapse {
  display: block;
}

.bet_collapse_body {
  padding: 9px 8px;
}

.betslip_button ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.betslip_button ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b9f1f4;
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
  height: 30px;
}

.custom-btn input {
  width: 100px;
  border: 1px solid #7bf1f9;
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
  outline: none;
  height: 32px;
  font-weight: 600;
}

.collapse_body_footer {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 9px 0px;
}

.select-stake {
  font-size: 12px;
  color: #b2bcd7;
  width: 60%;
}

.betslip-button {
  width: 40%;
  text-align: right;
}

.betslip-button button {
  width: 118px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  outline: none;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  position: relative;
  transition: all 0.35s;
  z-index: 1;
}

.betslip-button button:hover {
  color: #000000;
}

.betslip-button button::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.betslip-button button::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #a6de7f;
  transition: all 0.3s;
  z-index: -1;
}

.betslip-button button:hover::before {
  width: 100%;
}

.team_name span i {
  color: #03a41d;
}

.tab_row.main .scrore_btn ul li a {
  visibility: hidden;
  opacity: 0;
}

.drop_up a i {
  color: #03a41d;
}

.tab_row.main .drop_up a i {
  transform: rotate(-180deg);
  font-weight: 600;
}

/* modalcss============================ */
.modal_image {
  max-width: 300px;
}

.cross_btn {
  text-align: right;
  height: 0;
}

.login_page .modal-header {
  border: none;
  justify-content: center;
  padding: 0;
}

.login_page .modal-content {
  border-radius: 20px;
  padding: 8px 10px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: auto;
}

.login_page .modal-footer {
  border: none;
}

.login_page .modal-dialog {
  max-width: 700px;
}

.login_page .btn-close {
  border: none;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 12px;
  position: relative;
  z-index: 1;
}

.input_field {
  border-radius: 15px;
  border: 1px solid #e0efc8;
  background-color: #f7ffe8;
  padding: 5px 8px;
  position: relative;
  margin-bottom: 0;
}

.login_page .modal-body {
  display: flex;
  justify-content: space-around;
  padding: 45px 0px 0px;
}

.input_label {
  text-align: left;
  top: 6px;
  left: 12px;
  color: #595959;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  position: absolute;
}

.form_control {
  padding: 20px 0 0 5px;
}

.mobile_bg img {
  max-width: 272px;
}

.form_control {
  border: none;
  outline: none;
  background: none;
}

.alert_base span {
  color: #dd4b39;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.5;
  padding: 3px 0 0 10px;
}

.form_check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check_link a {
  display: inline-block;
  font-size: 11px;
  color: #5DAE32;
  font-weight: 600;
  padding: 0px 0px 0px 20px;
}

.form_check .form-check-label {
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  padding: 6px 5px 5px 0px;
}

.sinup_btn {
  padding: 5px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: linear-gradient(136.97deg, #ACFF80 3.04%, #369703 100%);
  border-radius: 16px;
  margin: 12px 0px;
  cursor: pointer;
  border: none;
}

.member {
  text-align: center;
  color: #858585;
  font-size: 12px;
  font-weight: 500;
}

.ragister_link {
  display: inline-block;
  font-size: 11px;
  color: #5DAE32;
  font-weight: 600;
}

.ragister_link:hover {
  color: #5DAE32;
}

.social_media ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social_media ul li {
  margin: 2px;
}

.social_media {
  margin-top: 10px;
}

.social_media ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #DAF5AE;
  border-radius: 50%;
}
.social_media ul li a img {
  width: 17px;
}

.widraw {
  font-size: 22px;
  font-weight: 700;
  color: #5DAE32;
  margin: 10px 0 5px;
  line-height: 1.2;
  font-style: italic;
}

.input_field i {
  color: #595959;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 13px;
  transform: translate(-50%, -50%);
  width: 16px;
  text-align: center;
}

.left-mobile-sidebar, .mobile-liveTv.showTv, .mobile-betlist-icon {
  display: none !important;
}

.main-body .footer_bg, .main-body .copy_right {
  display: none;
}

.search-top .dropdown-menu.show {
  height: 280px;
  overflow-y: auto;
  background-color: #5d6386;
  border-color: #414661;
  box-shadow: none;
  margin-top: 20px;
  padding: 0;
}
.search-top .dropdown-menu.show .dropdown-item {
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  border-bottom: 1px solid #414661;
  line-height: 32px;
}
.search-top .dropdown-menu.show .dropdown-item:hover {
  background-color: #414661;
}

@media (max-width: 767px) {
  a#darkmode {
    padding: 0 5px 0 10px;
    width: 60px;
  }

  table tr td {
    white-space: nowrap;
  }
  table tr td span {
    white-space: nowrap;
  }

  .list_menu {
    padding: 0 !important;
  }

  body .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, body .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, body .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    color: #ccc !important;
  }

  .footer-bottom h4, .footer-bottom .h4 {
    margin-top: 10px;
  }

  .footer_column {
    padding: 10px;
  }
  .footer_column p {
    margin-top: 20px;
  }

  .footer {
    padding-top: 10px;
  }

  .bet_collapse {
    width: 98%;
    margin-left: 1%;
  }

  .mobile-betlist-icon {
    display: block !important;
  }

  .odd-info .dot {
    font-size: 13px;
  }
  .odd-info .dot span {
    display: block;
    line-height: 20px;
    text-align: center;
  }

  .mobile-liveTv.showTv {
    display: block !important;
    padding-bottom: 10px;
    text-align: center;
  }
  .mobile-liveTv.showTv iframe {
    width: 100%;
  }

  span.count-betList {
    background: #55902d;
    color: #fff;
    width: fit-content;
    display: block;
    padding: 0 5px;
    border-radius: 4px;
    min-width: 20px;
    right: -5px;
    top: -8px;
    position: absolute;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
  }

  .bottom_tabs ul li.big {
    position: absolute;
    height: 70px;
    width: 20%;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 10px;
  }
  .bottom_tabs ul li.big .icon-span {
    display: none;
  }
  .bottom_tabs ul li.big a {
    position: relative;
    color: #000;
    top: -20px;
    background-color: transparent;
    width: auto;
    height: auto;
    margin-top: auto;
  }
  .bottom_tabs ul li.big a .fa-times {
    font-size: 18px;
    color: #000;
  }
  .bottom_tabs ul li.big a img {
    width: auto;
    height: 35px;
    display: inline-block;
    margin: auto;
    transition: all 0.2s ease-in;
  }
  .bottom_tabs ul li.big .toggle-switch {
    position: absolute;
    top: 25px;
    right: 0;
    margin: 0 2px;
  }
  .bottom_tabs ul li.big .toggle-switch .content {
    width: 20px;
    margin: auto;
    height: auto;
    background-color: transparent;
    display: flex;
    border-radius: 50%;
  }
  .bottom_tabs ul li.big .toggle-switch [type=checkbox]:not(:checked) + .switch-app label {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .bottom_tabs ul li.big .toggle-switch label {
    width: 100%;
    position: relative;
    cursor: pointer;
    border-radius: 100px;
    height: 40px;
    font-size: 0.8rem;
    color: #9e9e9e;
  }
  .bottom_tabs ul li.big .toggle-switch [type=checkbox] {
    display: none;
  }
  .bottom_tabs ul li.big .toggle-switch .toggle {
    width: 16px;
    height: 40px;
    position: absolute;
    transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-radius: 100px;
  }
  .bottom_tabs ul li.big .toggle-switch .names {
    width: 100%;
    height: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  .bottom_tabs ul li.big .toggle-switch .names p {
    margin: 0;
    font-weight: 600;
    font-size: 12px;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    text-align: center;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    text-align: initial;
    top: 13px;
    left: -1px;
  }
  .bottom_tabs ul li.big .toggle-switch .names p svg {
    width: 24px;
    height: 24px;
    margin-right: 0 !important;
    transition: all 0.5s;
  }
  .bottom_tabs ul li.big .toggle-switch [type=checkbox]:not(:checked) + .switch-app label .names .light svg {
    opacity: 0;
    margin-right: 0;
  }
  .bottom_tabs ul li.big .menu-item {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 85%;
    line-height: 5px;
    top: 60%;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    background-color: #a6de7f;
    transform: translate(0, 0);
    transition: transform 0.5s, -webkit-transform 0.5s;
    z-index: -2;
    box-shadow: -4px 1px 2px #8bc34a, 0px 0px 0px #8bc34a, -2px 0px 1px #8bc34a, 0px 0px 0px #8bc34a, inset -1px 1px 1px #bafd6c, inset 0px -1px 1px #8bc34a;
  }
  .bottom_tabs ul li.big .menu-item a {
    color: #000;
    position: relative;
    top: 12px;
    left: 0;
    text-decoration: none;
    font-size: 10px;
    line-height: 1.3;
    width: fit-content;
  }
  .bottom_tabs ul li.big .menu-item a svg {
    width: 26px;
    height: 26px;
    margin: auto;
  }
  .bottom_tabs ul li.big .blank-bg {
    box-shadow: none;
  }

  .big.show-on-home-page {
    display: none;
  }

  .modal .modal-dialog.search-modal .modal-content a.modal-close {
    right: 20px;
    font-size: 24px;
    top: 40px;
    position: absolute;
  }
  .modal .modal-dialog.search-modal .modal-content a.modal-close i {
    border-radius: 50%;
    padding: 6px;
    width: 35px;
    height: 35px;
    text-align: center;
    color: #5dae32;
    border: 2px solid #5dae32;
    font-size: 20px;
  }

  .search-modal {
    margin: 0 !important;
    height: 100%;
  }
  .search-modal .search-top .dropdown-menu.show {
    margin-top: 35px;
  }
  .search-modal .modal-content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    z-index: 999;
    top: 0 !important;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .search-modal .modal-body {
    position: relative;
    top: 5%;
    width: 100%;
    max-width: 1000px;
    text-align: center;
    margin-top: 30px;
    margin: auto;
    overflow-x: inherit;
  }
  .search-modal form {
    margin: 30px 0 !important;
    width: 100%;
    padding-top: 25px;
  }
  .search-modal form .search-icon {
    float: left;
    width: 15%;
    padding: 5px;
    color: #fff;
    background: #5dae32;
    font-size: 17px;
    border: none;
    cursor: pointer;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .search-modal form input.search_input {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 100%;
    background: #fff;
    height: 35px !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
    padding: 5px 0 5px 15px !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-position: 40% 0 !important;
    outline: none;
    box-shadow: none;
  }

  section.tab_part.home-page-footer .big.show-on-home-page {
    display: block;
  }
  section.tab_part.home-page-footer .big.show-on-home-page .blank-bg {
    width: fit-content;
    top: -5px;
  }
  section.tab_part.home-page-footer .big.bowler {
    display: none;
  }

  .bottom_tabs ul li.all-items .icon-span {
    display: block;
  }
  .bottom_tabs ul li.all-items img {
    display: none !important;
  }
  .bottom_tabs ul li.all-items .items:nth-child(2) {
    transform: rotate(-20deg) translateY(-95px) rotate(20deg);
    transition-delay: 0.2s;
  }
  .bottom_tabs ul li.all-items .items:nth-child(3) {
    transform: rotate(-60deg) translateY(-90px) rotate(60deg);
    transition-delay: 0.3s;
  }
  .bottom_tabs ul li.all-items .items:nth-child(4) {
    transform: rotate(20deg) translateY(-95px) rotate(-20deg);
    transition-delay: 0.1s;
  }
  .bottom_tabs ul li.all-items .items:nth-child(5) {
    transform: rotate(60deg) translateY(-90px) rotate(300deg);
    transition-delay: 0s;
  }
  .bottom_tabs ul li.all-items .bottom-circle {
    z-index: -3;
    position: absolute;
    top: 10%;
    left: 52%;
    transform: translate(-50%, -50%);
    height: 260px;
    width: 260px;
    border-radius: 160px 160px 0 0;
    background-color: #191E38;
    box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(215, 215, 215, 0.5), -6px 6px 12px rgba(215, 215, 215, 0.2), 6px -6px 12px rgba(215, 215, 215, 0.2), -1px 5px 10px rgba(215, 215, 215, 0.9);
  }

  li.big::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -17px;
    width: 60px;
    height: 60px;
    background-color: #a6de7f;
    border-radius: 100%;
    z-index: -1;
    margin: auto;
    box-shadow: -4.58667px 4.58667px 9.17333px rgba(230, 230, 230, 0.2), 4.58667px -4.58667px 9.17333px rgba(230, 230, 230, 0.2), -4.58667px -4.58667px 9.17333px rgba(255, 255, 255, 0.9), 4.58667px 4.58667px 11.9253px rgba(230, 230, 230, 0.9), inset -0.917333px -0.917333px 1.83467px rgba(230, 230, 230, 0.5);
  }

  .mobile-nav_slide {
    padding-left: 0;
    padding-right: 0;
  }

  .login_page.modal-dialog {
    position: fixed;
    width: 100%;
    bottom: 0;
    margin: 0;
  }
  .login_page.modal-dialog .modal-content {
    border-radius: 20px 20px 0 0;
    border: none;
  }
  .login_page.modal-dialog .form {
    width: 100%;
    padding: 0;
  }

  .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content {
    padding: 0 !important;
    width: 100% !important;
    margin-top: 10px;
  }

  .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content {
    width: 100% !important;
  }
  .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .upload_ss {
    padding: 10px 10px;
  }

  .accordion-item .collapsible {
    box-shadow: none;
  }

  .drop_up.text-right.toggle_icon {
    display: flex;
  }

  .fancy-div .col-md-4.col-4.text-center {
    order: 3;
  }

  .left-mobile-sidebar {
    display: block !important;
  }
  .left-mobile-sidebar .logo_search {
    display: block !important;
  }

  .fancy-div .suspend-cont {
    width: 100%;
  }

  .left_sidebar {
    display: none;
  }

  .right_sidebar {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    height: auto;
  }
  .right_sidebar .main_right_sidebar {
    height: auto;
    width: 100%;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .scrore_btn ul {
    justify-content: right;
  }
  .scrore_btn ul li {
    width: 60px;
  }

  .casion_game {
    padding: 0 0 10px;
  }

  .detail_page {
    padding: 15px 0 0;
  }

  .game_slider {
    padding: 8px;
  }

  .main_tabs {
    position: relative;
    overflow-x: scroll;
  }

  .all_casion_game {
    position: relative;
    overflow-x: scroll;
  }

  .promoting_tabs {
    padding: 10px 10px 5px !important;
  }

  .modal .change-password.modal-dialog a.btn {
    max-width: 150px !important;
  }
}
.modal {
  z-index: 999 !important;
}

.modal-backdrop {
  z-index: inherit !important;
}

.bottom-border svg {
  width: 100%;
}

.modal .close-icon {
  right: 2%;
  position: absolute;
  top: 2%;
  z-index: 9999;
  background-color: #535776;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 3px;
}

.modal .close-icon i {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}

.modal h5, .modal .h5 {
  position: relative;
}

.modal h5 .card-title, .modal .h5 .card-title {
  margin: auto;
  position: relative;
  top: 0;
  left: -30px;
  right: 0;
  width: 240px;
  height: 60px;
  z-index: 9;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal h5 .card-title img, .modal .h5 .card-title img {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;
}

.modal h5 .card-title .title, .modal .h5 .card-title .title {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  z-index: 9;
  text-transform: capitalize;
}

.avatar .avtar-dropdow li a svg path {
  color: #5dae32;
  stroke: none;
  fill: #5dae32;
}

.logo_image {
  max-width: 190px;
  object-fit: contain;
  height: 70px;
}

.no-inplay-match p {
  margin-top: 0;
  background-color: #dadada;
  padding: 3px 8px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px;
  color: #2c2a2a;
}
.no-inplay-match p strong {
  font-weight: 600;
  color: #03a41d;
}

@media (max-width: 991.98px) {
  /* home_page_meadia-querry========================================== */
  .item_wrapper {
    display: block;
  }

  .top_title {
    font-size: 25px;
    text-align: center;
  }

  .mid_title {
    font-size: 25px;
    text-align: center;
  }

  .bottom_title {
    text-align: center;
    font-size: 20px;
  }

  .carosal_image img {
    max-width: 50%;
  }

  .sport_list {
    justify-content: center;
  }

  .sport_list li a {
    width: 159px;
    height: 50px;
    font-size: 12px;
  }

  .carosal_image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .top_section {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .right-arrow {
    display: none;
  }

  .col_btn {
    width: 100%;
    margin: 0px 3px 8px;
  }

  .title {
    font-size: 16px;
    color: #ffffff;
  }

  .download-btn {
    text-align: center;
  }

  .app_title_base {
    text-align: center;
  }

  .app_title_base strong {
    font-size: 25px;
  }

  .app_title_base h5, .app_title_base .h5 {
    font-size: 25px;
  }

  .testimonial_title h3, .testimonial_title .h3 {
    font-size: 28px;
  }

  .app_image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app_image img {
    max-width: 50%;
  }

  .item_1 {
    text-align: center;
  }

  .item {
    text-align: center;
  }

  .social_icon_listing ul {
    flex-wrap: wrap;
  }

  .social_icon_listing ul li a {
    width: 50%;
  }

  .app_wrapper .app_slider .owl-theme .owl-dot span {
    display: none;
  }

  .tab_part .bottom_tabs ul li a {
    background-color: #1f1f1f;
  }

  .tab_part .bottom_tabs ul li .active {
    background-color: #1f1f1f;
    box-shadow: unset;
  }

  .tab_part .bottom_tabs ul li .active:hover {
    background-color: #a6de7f;
  }

  .tab_part .bottom_tabs ul li a:hover {
    box-shadow: unset;
  }

  .tab_part .bottom_tabs ul li.big::before {
    background: #252525;
    box-shadow: none;
  }

  .testimonial-section {
    background-size: contain;
    background-position-y: 0%;
  }

  .right_icon .right_list li .active {
    background-color: #7ab95b;
    display: inline-flex;
  }

  /* home_page_meadia-querry========================================== */
  .burger_menu {
    display: block;
    margin: 0px 5px;
  }

  .left-mobile-sidebar {
    position: absolute;
  }

  .main_left_sidebar {
    position: fixed;
    left: -100%;
    flex-direction: column;
    background-color: #5dae32;
    width: 75%;
    text-align: center;
    transition: 0.5s;
    height: 100%;
    z-index: 99999;
    top: 0;
    border-radius: 0;
  }

  .sidebar_accordian .accordion-button {
    background-color: #5dae32;
    color: #ffffff;
    transition: 0.3s ease-out;
    padding: 10px 9px;
    border-radius: 5px;
  }

  .sidebar_accordian .accordion-button:not(.collapsed) {
    background-color: #5dae32;
  }

  .sidebar_accordian .accordion-body {
    background-color: #5dae32;
  }

  .collapsible li a {
    color: #ffffff;
    text-align: left;
  }

  .left_sidebar_title {
    display: none;
  }

  .sidebar_accordian .accordion-button.collapsed::after {
    color: #ffffff;
  }

  .sidebar_accordian .accordion-button[aria-expanded=true]::after {
    color: #fff;
  }

  .page_header .logo_search {
    display: none;
  }

  .header_mid_aside {
    display: none;
  }

  .logo_image {
    max-width: 155px;
  }

  .right_list li a {
    padding: 0px 10px;
  }

  .right_list li .active {
    display: none;
  }

  .sidebar_accordian .accordion-button:focus {
    background-color: #5dae32;
  }

  .left_fix_menu {
    display: flex;
    align-items: center;
  }

  .fix_menu_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right_fix_menu ul li a svg {
    width: 17px;
    height: 17px;
  }

  .right_fix_menu ul li a svg path {
    fill: #ffffff;
  }

  .right_fix_menu ul li {
    margin-left: 5px;
  }

  .arrow_link {
    margin: 0px 4px;
  }

  .serch_input {
    width: 150px;
    background-color: #7ab95b;
  }

  .right_fix_menu ul {
    display: flex;
    align-items: center;
  }

  .right_fix_menu ul li a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #7ab95b;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .page_logo {
    margin-right: 15px;
  }

  .arrow_link a i {
    color: #ffffff;
    font-size: 18px;
  }

  .accordian-item {
    display: block;
    text-align: left;
  }

  .sidebar_accordian {
    margin-top: 20px;
  }

  .sidebar_accordian .accordion-button.demo {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 10px 9px;
    border-radius: 5px;
  }

  .sidebar_accordian .accordion-item {
    background: none;
  }

  .sidebar_accordian .accordion-button.demo svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .sidebar_accordian .accordion-button.demo::after {
    display: none;
  }

  .mobile-nav_slide {
    width: 100%;
    margin: 0;
  }

  .left_sidebar {
    padding: 0;
    position: unset;
    left: unset;
    right: unset;
  }

  .right_sidebar {
    width: 100%;
    position: unset;
    height: auto;
  }

  .icons {
    text-align: left;
  }

  .contact-details {
    text-align: left;
  }

  .mobile_bg img {
    display: none;
  }

  .login_page .modal-body {
    display: block;
    padding: 45px 30px 30px;
  }

  .widraw {
    display: none;
  }

  .bottom_tabs {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    font-size: 13px;
    z-index: 99;
    background-color: #a6de7f;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: block;
  }

  .bottom_tabs ul {
    display: flex;
  }

  .bottom_tabs ul li .active {
    width: 41px;
    height: 41px;
    margin-bottom: auto;
  }

  .bottom_tabs ul li a {
    width: 37px;
    height: 37px;
    margin: auto;
    background-color: #5dae32;
    border-radius: 50%;
    align-items: center;
    top: 0;
    transition: all 0.2s ease-in;
    color: #000;
    cursor: pointer;
    font-size: 11px;
    justify-content: center;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-top: 10px;
  }

  .bottom_tabs ul li {
    align-items: center;
    height: 70px;
    flex-basis: 20%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bottom_tabs ul li a svg {
    display: inline-block;
    margin: auto;
    width: 23px;
    height: 23px;
  }

  .bottom_tabs ul li.all-sports a.active svg path, .bottom_tabs ul li.all-sports a:hover svg path {
    stroke: none;
    fill: #fff;
  }

  .bottom_tabs ul li a.blank-bg {
    background-color: transparent;
    width: auto;
    height: auto;
  }

  .bottom_tabs ul li.big::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -17px;
    width: 60px;
    height: 60px;
    background-color: #A6DE7F;
    border-radius: 100%;
    z-index: -1;
    margin: auto;
    box-shadow: -4.58667px 4.58667px 9.17333px rgba(230, 230, 230, 0.2), 4.58667px -4.58667px 9.17333px rgba(230, 230, 230, 0.2), -4.58667px -4.58667px 9.17333px rgba(255, 255, 255, 0.9), 4.58667px 4.58667px 11.9253px rgba(230, 230, 230, 0.9), inset -0.917333px -0.917333px 1.83467px rgba(230, 230, 230, 0.5);
  }

  .bottom_tabs ul li.big a img {
    width: auto;
    height: 35px;
  }

  .title_name {
    color: #a7a7a7;
    z-index: 9;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 2px;
  }

  .sports_section {
    padding: 0;
  }

  .sports_col {
    margin: 0 0 10px;
    clear: both;
  }
  .sports_col .sports_image {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
  .sports_col .sports_content {
    width: 50%;
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
  }
  .sports_col .sports_content h5, .sports_col .sports_content .h5 {
    margin: 0 0 10px;
    font-size: 16px;
  }

  .partner_section {
    padding: 10px 0;
  }
  .partner_section .top_section {
    padding: 0;
  }
  .partner_section .top_section .col_btn {
    width: calc( 50% - 6px );
    margin: 0 3px 8px;
  }

  .testimonial_slide .carousel-item {
    width: 100% !important;
  }

  .banner_section {
    padding: 25px 0;
  }
  .banner_section .banner_wrapper {
    padding: 0;
  }

  .page_footer {
    padding: 50px 15px 10px;
  }

  .bottom_tabs ul li a svg circle, .bottom-tabs ul li a svg path {
    stroke: #fff;
  }

  .bottom_tabs ul li a svg path {
    stroke: #fff;
  }

  .bottom-tabs ul li.all-sports a svg path {
    stroke: none;
    fill: #fff;
  }

  .bottom_tabs .dropdown-menu.avtar-dropdow.show li a svg path {
    color: #5dae32;
    stroke: none;
    fill: #5dae32;
  }

  .bottom_tabs ul li a img {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    transition: all 0.2s ease-in;
  }

  .bottom_tabs ul li a.active, .bottom_tabs ul li a:hover, .bottom_tabs ul li a:focus {
    background-color: #a6de7f;
    width: 41px;
    height: 41px;
    margin-bottom: auto;
  }

  .bottom_tabs ul li.big a {
    position: relative;
    color: #000;
    top: -20px;
    background-color: transparent;
    width: auto;
    height: auto;
    margin-top: auto;
  }

  .bottom-tabs ul li.big::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -17px;
    width: 60px;
    height: 60px;
    background-color: var(--bottom-tab-bg);
    border-radius: 100%;
    z-index: -1;
    margin: auto;
  }

  .market_row {
    display: block;
    margin-bottom: 4px;
  }

  .basic_row {
    width: 100%;
    margin-bottom: 2px;
  }

  .table_row {
    width: 100%;
    display: block;
  }

  .text {
    text-align: center;
  }

  .badge-right ul {
    justify-content: flex-end;
    margin-bottom: 2px;
  }

  .game_name {
    font-size: 10px;
  }

  .game_provider ul {
    width: 100%;
  }

  .all_providers {
    width: 100%;
  }

  .game_provider ul li a {
    justify-content: flex-start;
    width: 100%;
  }

  .port-inner {
    width: 50%;
    border: 1px solid gold;
  }

  .sports_col {
    display: block;
  }

  .sports_image img {
    max-width: 100%;
  }

  .download-btn {
    padding: 20px 0px;
  }

  .download_link {
    width: 140px;
    height: 45px;
  }

  .open_bet_row .form-select, .kyc-dropdown {
    margin-bottom: 8px;
  }

  .open_bet_row .form-select.select_form {
    width: 100%;
  }

  .datepicker_input a.search {
    margin: 0 0 10px !important;
  }

  .select-bank .bank-details .add-bank {
    width: 160px !important;
  }

  .open_bet_row {
    margin-bottom: 0;
  }

  .date_picker .kyc-dropdown {
    margin-right: 2px;
  }

  /* reporting_page_mediaquerry============================= */
  .bottom-header {
    padding-top: 51px;
    height: unset !important;
  }
  .bottom-header .news_feed {
    padding-left: 5px;
    position: fixed;
  }

  .mobile-nav_slide.nav_slide_mobile {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }

  .wrapper_gamepage.gamepage_wrapper {
    display: block;
  }

  .bottom-content {
    display: block;
  }

  .bottom-content .bottom_discription {
    width: calc(33% - 10px);
    padding: 0;
    margin: 20px 5px 0;
    display: inline-block;
  }
  .bottom-content .bottom_discription .title {
    font-size: 12px !important;
    text-transform: capitalize;
  }
  .bottom-content .bottom_discription .discription {
    font-size: 20px;
    margin: 10px 0 10px;
  }

  .user_col {
    margin-bottom: 10px;
    height: unset;
  }

  .nav.navigation {
    margin-bottom: 0 !important;
    flex-wrap: nowrap;
    overflow: auto;
  }

  table.dataTable thead td, table.dataTable thead th {
    white-space: nowrap;
  }

  .bankList-table table {
    width: 100%;
  }

  .nav.navigation::-webkit-scrollbar {
    overflow: hidden;
  }

  .data_table {
    overflow: auto;
  }

  .data_table::-webkit-scrollbar {
    overflow: hidden;
  }

  .user-settings {
    display: none;
  }

  .hide_on {
    display: none;
  }

  .avatar {
    display: none;
  }

  .upload-id {
    width: 100%;
  }

  .kyc-dropdown {
    max-width: 100%;
  }

  .date_picker {
    display: block;
  }

  .no-wallet a {
    width: auto;
    min-width: 89px;
  }

  .deposit a {
    height: 33px;
    line-height: 33px;
    font-size: 11px;
    width: 73px;
    min-width: 70px;
    margin-left: auto;
    top: 0;
    position: relative;
  }

  .row.p-0.select-bank .input-field.col.m6,
.row.m-0.withdraw-bank .input-field.col.m6 {
    width: 100%;
  }

  .upload-id .btn a {
    top: 0;
  }

  .upload-id .btn .file_input[type=file] {
    position: unset;
  }

  .data_table .dataTables_length {
    text-align: left;
  }

  .data_table .dataTables_wrapper .dataTables_filter {
    text-align: center;
  }

  /* reporting_page_mediaquerry============================= */
}
@media (max-width: 767.98px) {
  .swiper-wrapper {
    flex-wrap: wrap !important;
  }

  .datepicker_input.fromDate_input {
    width: 50%;
    display: inline-block;
    padding-right: 5px;
  }

  .datepicker_input.toDate_input {
    width: 50%;
    display: inline-block;
    padding-left: 5px;
  }

  .reporting_tabs .date_picker {
    padding: 10px 10px 0 10px;
  }

  .burger_menu {
    display: block;
    margin: 0;
  }

  .user-menus li.deposit a {
    width: 80px !important;
  }

  .user-menus li a.dropdown-trigger {
    margin-left: 0;
    min-width: 100px;
  }

  .value-type-header {
    display: none;
  }

  .s_icon {
    z-index: 9;
  }

  .otp-page .otp-details .otp-verify input[type=text] {
    width: 40px !important;
    height: 40px !important;
    border-radius: 10px !important;
  }

  .search-top .dropdown-menu.show {
    width: 320px;
  }

  .search-top .dropdown-menu.show .dropdown-item {
    line-height: 30px;
  }

  .user_col {
    padding: 12px 10px 16px;
  }

  .main_left_sidebar {
    position: fixed;
    left: -100%;
    flex-direction: column;
    background-color: #191E38;
    width: 75%;
    text-align: center;
    transition: 0.5s;
    height: 100%;
    z-index: 99999;
    top: 52px;
    border-radius: 0;
    padding: 0;
    overflow: auto;
    height: calc(100% - 52px);
  }

  .sidebar_accordian .accordion-button {
    background-color: #191E38;
    color: #ffffff;
    transition: 0.3s ease-out;
    padding: 10px 9px;
    border-radius: 5px;
  }

  li.no-wallet.dropdown:hover ul#dropdown {
    display: block;
    top: 40px !important;
    transform: none !important;
  }

  .sidebar_accordian .accordion-button:not(.collapsed) {
    background-color: #191E38;
  }

  .sidebar_accordian .accordion-body {
    background-color: #191E38;
  }

  .collapsible li a {
    color: #ffffff;
    text-align: left;
  }

  .left_sidebar_title {
    display: none;
  }

  .sidebar_accordian .accordion-button.collapsed::after {
    color: #ffffff;
  }

  .sidebar_accordian .accordion-button[aria-expanded=true]::after {
    color: #fff;
  }

  .page_header .logo_search {
    display: none;
  }

  .header_mid_aside {
    display: none;
  }

  .logo_image {
    max-width: 100px;
    object-fit: contain;
  }

  .right_list li a {
    padding: 0 10px;
  }

  .right_list li .active {
    display: none;
  }

  .sidebar_accordian .accordion-button:focus {
    background-color: #5dae32;
  }

  .left_fix_menu {
    display: flex;
    align-items: center;
  }

  .fix_menu_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .right_fix_menu ul li a svg {
    width: 17px;
    height: 17px;
  }

  .right_fix_menu ul li a svg path {
    fill: #ffffff;
  }

  .right_fix_menu ul li {
    margin-left: 5px;
  }

  .arrow_link {
    margin: 0px 4px;
  }

  .serch_input {
    width: 150px;
    background-color: #7ab95b;
  }

  .right_fix_menu ul {
    display: flex;
    align-items: center;
  }

  .right_fix_menu ul li a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #7ab95b;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .page_logo {
    margin-right: 5px;
    margin-left: -40px;
    text-align: left;
    width: 100%;
  }

  .hideSidebar {
    display: none !important;
  }

  .arrow_link a i {
    color: #ffffff;
    font-size: 18px;
  }

  .accordian-item {
    display: block;
    text-align: left;
  }

  .modal .manual-deposit.modal-dialog {
    width: 100%;
  }
  .modal .manual-deposit.modal-dialog .modal-body {
    padding: 0 0px 15px;
  }
  .modal .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .select-transfer a {
    padding: 10px;
    margin: 0 5px 10px;
  }

  .stack-edit.login_page .modal-header {
    text-align: left;
  }
  .stack-edit.login_page .modal-body {
    padding: 10px;
  }
  .stack-edit.login_page .input_field {
    width: calc(50% - 2%);
    display: inline-block;
    margin: 0 1% 10px;
    border-radius: 10px;
  }
  .stack-edit.login_page .input_field .form_control {
    padding: 0 10px;
  }
  .stack-edit.login_page .submit-btn {
    padding: 0;
  }
  .stack-edit.login_page .submit-btn a {
    width: 120px;
  }

  .login_page.bet-list {
    width: 98%;
    margin-left: 1%;
    height: 98%;
    bottom: 1%;
  }

  .login_page.bet-list .modal-content {
    border-radius: 20px;
    height: -webkit-fill-available;
  }
  .login_page.bet-list .modal-content .modal-header {
    justify-content: left;
  }
  .login_page.bet-list .modal-content .modal-header h5, .login_page.bet-list .modal-content .modal-header .h5 {
    padding-top: 5px;
  }
  .login_page.bet-list .modal-content .modal-body {
    padding: 15px 0px 20px;
  }

  .sidebar_accordian {
    margin-top: 20px;
    overflow-y: auto;
    height: calc(100vh - 136px);
  }
  .sidebar_accordian .accordion {
    padding: 0 5px;
  }
  .sidebar_accordian .accordion-button:before {
    border-radius: 5px;
  }
  .sidebar_accordian .accordion-button:not(.collapsed) {
    border-radius: 5px;
    padding: 5px 15px;
    line-height: 25px;
    background-color: #262D50;
    color: #fff;
  }
  .sidebar_accordian .accordion-button:not(.collapsed)::after {
    color: #fff;
  }

  .accordion-collapse.collapse.show ul.accordion-body {
    padding-left: 10px;
  }
  .accordion-collapse.collapse.show ul.accordion-body li {
    margin-bottom: 0;
  }
  .accordion-collapse.collapse.show ul.accordion-body .accordion-item {
    line-height: 26px;
    margin-bottom: 2px;
  }
  .accordion-collapse.collapse.show ul.accordion-body #hThree li {
    text-align: left;
  }
  .accordion-collapse.collapse.show ul.accordion-body #hThree li a {
    width: calc(100% - 20px);
  }
  .accordion-collapse.collapse.show ul.accordion-body #hThree li a:after {
    font-family: Font Awesome\ 6 Free;
    background-image: none;
    content: "+";
    font-weight: 900;
    color: #fff;
    background-image: unset;
    text-align: right;
    transform: rotate(-180deg);
    position: absolute;
    right: -10px;
  }
  .accordion-collapse.collapse.show ul.accordion-body #hThree li a[aria-expanded=true]:after {
    transform: rotate(-45deg);
    background: unset;
  }
  .accordion-collapse.collapse.show ul.accordion-body .accordion-body {
    padding-left: 10px;
  }
  .accordion-collapse.collapse.show ul.accordion-body .accordion-body ul li a {
    color: #fff;
  }

  .sidebar_accordian .accordion-button.demo {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    padding: 10px 9px;
    border-radius: 5px;
  }

  .sidebar_accordian .accordion-item {
    background: none;
  }

  .sidebar_accordian .accordion-button.demo svg {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .sidebar_accordian .accordion-button.demo::after {
    display: none;
  }

  .mobile-nav_slide {
    width: 100%;
    margin: 0;
    padding-top: 10px;
  }
  .mobile-nav_slide .slider .item img {
    min-height: 99px;
  }

  .left_sidebar {
    padding: 0;
    position: unset;
    left: unset;
    right: unset;
  }

  .right_sidebar {
    width: 100%;
    position: unset;
    height: auto;
  }

  .icons {
    text-align: left;
  }

  .contact-details {
    text-align: left;
  }

  .mobile_bg img {
    display: none;
  }

  .login_page .modal-body {
    display: block;
    padding: 45px 30px 30px;
  }

  .widraw {
    display: none;
  }

  .bottom_tabs {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    font-size: 13px;
    z-index: 99;
    background-color: #191E38;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: block;
  }

  .bottom_tabs ul {
    display: flex;
  }

  .bottom_tabs ul li .active {
    width: 41px;
    height: 41px;
    margin-bottom: auto;
  }

  .bottom_tabs ul li a {
    width: 37px;
    height: 37px;
    margin: auto;
    background-color: #5dae32;
    border-radius: 50%;
    align-items: center;
    top: 0;
    transition: all 0.2s ease-in;
    color: #000;
    cursor: pointer;
    font-size: 11px;
    justify-content: center;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    letter-spacing: 0.3px;
    margin-top: 10px;
  }

  .bottom_tabs ul li {
    align-items: center;
    height: 70px;
    flex-basis: 20%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .bottom_tabs ul li a svg {
    display: inline-block;
    margin: auto;
    width: 23px;
    height: 23px;
  }

  .bottom_tabs ul li.all-sports a.active svg path, .bottom_tabs ul li.all-sports a:hover svg path {
    stroke: none;
    fill: #fff;
  }

  .bottom_tabs ul li a.blank-bg {
    background-color: transparent;
    width: auto;
    height: auto;
  }

  .bottom_tabs ul li.big::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -17px;
    width: 60px;
    height: 60px;
    background-color: #A6DE7F;
    border-radius: 100%;
    z-index: -1;
    margin: auto;
    box-shadow: -4.58667px 4.58667px 9.17333px rgba(0, 0, 0, 0.2), 4.58667px -4.58667px 9.17333px rgba(0, 0, 0, 0.2), -4.58667px -4.58667px 9.17333px rgba(0, 0, 0, 0.9), 4.58667px 4.58667px 11.9253px rgba(0, 0, 0, 0.9), inset -0.917333px -0.917333px 1.83467px rgba(0, 0, 0, 0.5);
  }

  .bottom_tabs ul li.big a img {
    width: auto;
    height: 35px;
  }

  .title_name {
    color: #fff;
    z-index: 9;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    margin-bottom: 2px;
  }

  .bottom_tabs ul li a svg circle, .bottom-tabs ul li a svg path {
    stroke: #fff;
  }

  .bottom_tabs ul li a svg path {
    stroke: #fff;
  }

  .bottom-tabs ul li.all-sports a svg path {
    stroke: none;
    fill: #fff;
  }

  .bottom_tabs ul li a img {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    transition: all 0.2s ease-in;
  }

  .bottom_tabs ul li a.active, .bottom_tabs ul li a:hover {
    background-color: #a6de7f;
    width: 41px;
    height: 41px;
    margin-bottom: auto;
  }

  .bottom_tabs ul li.big a {
    position: relative;
    color: #000;
    top: -20px;
    background-color: transparent;
    width: auto;
    height: auto;
    margin-top: auto;
  }

  .bottom-tabs ul li.big::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -17px;
    width: 60px;
    height: 60px;
    background-color: var(--bottom-tab-bg);
    border-radius: 100%;
    z-index: -1;
    margin: auto;
  }

  .market_row {
    display: block;
  }

  .table_row {
    width: 100%;
    display: block;
  }

  header .page_header {
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
    padding: 0 10px;
  }

  .text {
    text-align: center;
  }

  .badge-right ul {
    justify-content: flex-end;
    margin-bottom: 2px;
  }

  .game_name {
    font-size: 10px;
  }

  .game_provider ul {
    width: 100%;
  }

  .all_providers {
    width: 100%;
  }

  .user-menus li.no-wallet a {
    min-width: 100px;
  }

  .game_provider ul li a {
    justify-content: flex-start;
    width: 100%;
  }

  .port-inner {
    width: 50%;
  }

  .sports_col {
    display: block;
  }

  .sports_image img {
    max-width: 100%;
  }

  .download-btn {
    padding: 20px 0px;
  }

  .download_link {
    width: 140px;
    height: 45px;
  }

  .item_wrapper {
    display: block;
  }

  .top_title {
    font-size: 25px;
    text-align: center;
  }

  .mid_title {
    font-size: 25px;
    text-align: center;
  }

  .bottom_title {
    text-align: center;
    font-size: 20px;
  }

  .carosal_image img {
    max-width: 50%;
  }

  .sport_list {
    justify-content: center;
  }

  .sport_list li a {
    width: 159px;
    height: 50px;
    font-size: 12px;
  }

  .carosal_image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
  }

  .top_section {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .right-arrow {
    display: none;
  }

  .col_btn {
    width: 100%;
    margin: 0px 3px 8px;
  }

  .title {
    font-size: 14px;
    color: #ffffff;
  }

  .download-btn {
    text-align: center;
  }

  .app_title_base {
    text-align: center;
  }

  .app_title_base strong {
    font-size: 25px;
  }

  .app_title_base h5, .app_title_base .h5 {
    font-size: 25px;
  }

  .testimonial_title h3, .testimonial_title .h3 {
    font-size: 28px;
  }

  .app_image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app_image img {
    max-width: 50%;
  }

  .item_1 {
    text-align: center;
  }

  .item {
    text-align: center;
  }

  .social_icon_listing ul {
    flex-wrap: wrap;
  }

  .social_icon_listing ul li a {
    width: 50%;
  }

  .app_wrapper .app_slider .owl-theme .owl-dot span {
    display: none;
  }

  .tab_part .bottom_tabs ul li a {
    background-color: #1f1f1f;
  }

  .tab_part .bottom_tabs ul li .active {
    background-color: #1f1f1f;
    box-shadow: unset;
  }

  .tab_part .bottom_tabs ul li .active:hover {
    background-color: #a6de7f;
  }

  .tab_part .bottom_tabs ul li a:hover {
    box-shadow: unset;
    background: #A6DE7F;
  }

  .tab_part .bottom_tabs ul li.big::before {
    background: #252525;
    box-shadow: none;
  }

  .testimonial-section {
    background-size: contain;
    background-position-y: 0%;
  }

  .right_icon .right_list li .active {
    background-color: #7ab95b;
    display: inline-flex;
  }
}
@media (min-width: 768px) {
  .withdraw-bank .pr-10 {
    padding-right: 10px !important;
  }
  .withdraw-bank .pl-10 {
    padding-left: 10px !important;
  }
}
.right {
  float: right !important;
}

.left {
  float: left !important;
}

table {
  width: 100%;
}

.file-field input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.manual-deposit.modal-dialog {
  width: 60%;
  max-width: 600px;
  height: 85%;
  max-height: 85%;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.07);
  top: 7.5% !important;
  bottom: 0;
  margin: auto;
}
.manual-deposit.modal-dialog .modal-content {
  height: 100% !important;
}
.manual-deposit.modal-dialog .upload-id {
  width: 100%;
}
.manual-deposit.modal-dialog .upload-id img {
  max-width: 100%;
  margin: 0 auto;
}
.manual-deposit.modal-dialog .close-icon {
  right: 2%;
  position: absolute;
  top: 2%;
  z-index: 9999;
  background-color: #535776;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}
.manual-deposit.modal-dialog .close-icon i {
  font-size: 18px;
  color: #fff;
}
.manual-deposit.modal-dialog .modal-body {
  padding: 0 25px 15px 25px;
  max-height: 100%;
  overflow-y: auto;
  height: 100% !important;
}
.manual-deposit.modal-dialog .modal-body .body-inner {
  padding: 15px 10px 10px 10px;
  width: 100%;
  font-size: 12px;
  font-weight: 600;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method {
  padding: 10px 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .select-transfer {
  display: flex;
  justify-content: center;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .select-transfer a {
  text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  color: #000;
  padding: 15px;
  margin: 15px 7px 10px;
  position: relative;
  width: 160px;
  height: 75px;
  border-radius: 10px;
  border: 1px solid #e0efc8;
  display: flex;
  align-items: center;
  background: linear-gradient(134.47deg, #F4F4F4 2.84%, #FFFFFF 100%);
  overflow: hidden;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .select-transfer a:hover, .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .select-transfer a.active {
  border: 1px solid #657c40;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .select-transfer img {
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin: 0 auto;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details {
  display: block;
  float: left;
  width: 100%;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details .title {
  font-size: 12px;
  font-weight: 500;
  color: #ccc;
  margin: 5px 0;
  text-transform: capitalize;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details .phone-pay-details {
  background-color: #f7ffe8;
  padding: 10px;
  border-radius: 10px;
  margin: 5px 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table tr {
  border: none;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table tr td {
  padding: 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table .right-align {
  text-align: right;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table td.title {
  color: #595959;
  font-size: 12px;
  font-weight: 500;
  width: 267px;
  padding: 0 3px;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table td.sub-title {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  padding: 0 3px;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table td.copy-img {
  cursor: pointer;
  padding: 0 0 0 5px;
  text-align: center;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table td.copy-img svg {
  width: 18px;
  height: 18px;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table tr.qr-codes .left-col .qr-title {
  text-transform: capitalize;
  color: #595959;
  font-size: 13px;
  font-weight: 500;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details table tr.qr-codes .right-col img {
  max-width: 125px;
  padding: 5px 3px 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details {
  width: 100%;
  float: left;
  position: relative;
  display: block;
  margin: 5px 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content {
  float: left;
  display: block;
  margin-right: 10px;
  width: 55%;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .amount-bg {
  background-color: #f7ffe8;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .amount {
  border-radius: 10px;
  background-color: #fff;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .amount input {
  padding: 7px !important;
  width: 95%;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .add-amount {
  font-size: 10px;
  font-weight: 500;
  color: #595959;
  margin: 5px 0;
  text-transform: capitalize;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .add-amount strong {
  color: #5dae32;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .left-content .amount-bg:last-child {
  margin-bottom: 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content {
  float: right;
  background-color: #262D50;
  padding: 20px 10px;
  border-radius: 10px;
  width: 42%;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content .upload_ss {
  width: 100%;
  float: left;
  background-color: #ebebeb;
  border-radius: 10px;
  padding: 20px 10px;
  margin: 10px 0;
  text-transform: capitalize;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content .upload_ss .btn {
  line-height: 40px;
  color: #595959;
  font-size: 12px;
  font-weight: 500;
  border-radius: 10px;
  background-color: transparent;
  box-shadow: none;
  text-transform: capitalize;
  display: grid;
  width: 100%;
  height: 100%;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content .upload_ss .btn a {
  position: relative;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 1px solid #000;
  top: 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content .upload_ss .btn a i {
  font-size: 12px;
  color: #000;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content .upload_ss .btn .upload-title {
  line-height: 1.3;
  margin-top: 5px;
  color: #000;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .user-warning-note {
  width: 100%;
  float: left;
  position: relative;
  display: block;
  margin: 5px 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .user-warning-note .title {
  font-size: 13px;
  font-weight: 600;
  color: #d9534f;
  margin: 2px 0;
  text-transform: capitalize;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .user-warning-note .title i {
  animation-name: blinker2;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .user-warning-note .note-content {
  font-size: 11px;
  font-weight: 600;
  color: #d9534f;
  margin: 5px 0 5px;
  line-height: 1.2;
}
.manual-deposit.modal-dialog .modal-body .body-inner .input-field {
  padding: 2px 8px;
  margin: 0;
  background-color: #f7ffe8;
  border: 1px solid #e0efc8;
  border-radius: 15px;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn {
  margin: 30px auto 10px;
  display: block;
  float: left;
  width: 100%;
  padding: 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn .btn {
  position: relative;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  max-width: 150px;
  height: 45px;
  display: block;
  float: right;
  color: #fff;
  line-height: 36px;
  font-size: 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: auto;
  background-color: #1d4a27;
  border: none;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn .btn:hover {
  z-index: 1;
  position: relative;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn .btn::before {
  opacity: 0;
  -webkit-transform-origin: center center 0 50%;
  transform-origin: center center 0 50%;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  position: absolute;
  content: attr(data-effect);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 0;
  left: 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn .btn:hover .animate-btn {
  color: #5dae32;
  z-index: 9;
  position: relative;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn .btn::after {
  opacity: 1;
  top: 0;
  left: 0;
  right: 100%;
  bottom: 0;
  color: transparent;
  z-index: 0;
  border-radius: 4px;
}
.manual-deposit.modal-dialog .modal-body .body-inner .submit-btn .btn:hover::after {
  background-color: #a6de7f !important;
  -webkit-transition: all ease-in 0.4s;
  transition: all ease-in 0.4s;
  right: 0;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-footer {
  text-align: center;
  padding: 15px 10px;
  position: relative;
  width: 100%;
  bottom: 0;
  z-index: 1;
  display: -ms-grid;
  display: grid;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-footer p {
  color: #ccc;
  font-size: 11px;
  line-height: 15px;
  margin: 0 0 8px;
  font-weight: 500;
}
.manual-deposit.modal-dialog .modal-body .body-inner .payment-footer img {
  width: auto;
  height: 20px;
  margin: auto;
  margin-bottom: 3px;
}
.manual-deposit.modal-dialog form {
  margin: 5px;
}
.manual-deposit.modal-dialog form .input-field input {
  border: none;
  padding: 5px 0 0 5px !important;
  border-bottom: 1px solid transparent !important;
  color: #000;
  display: block;
  width: 100%;
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 0;
  margin-bottom: 10px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}
.manual-deposit.modal-dialog form .input-field input {
  margin-bottom: 0 !important;
  padding: 20px 0 0 5px !important;
  height: 40px !important;
  outline: none;
  box-shadow: none;
}

.coundown.live-match-title {
  display: block;
}

.matchDetail-header {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 8px;
  padding: 5px 10px;
  margin-bottom: 10px;
}
.matchDetail-header .inner-right {
  display: flex;
  align-items: center;
  justify-content: right;
  line-height: 36px;
}
.matchDetail-header .inner-right .event-timer {
  padding-right: 15px;
}
.matchDetail-header .inner-right .header-icon ul {
  display: flex;
  padding-left: 15px;
}
.matchDetail-header .inner-right .header-icon ul li {
  padding: 5px;
  line-height: normal;
}
.matchDetail-header .inner-right .header-icon ul li a {
  display: block;
}
.matchDetail-header .inner-right .header-icon ul li a img {
  height: 20px;
}

.modal form {
  margin: 30px 15px;
}
.modal .modal-fixed-footer .modal-content {
  height: 100%;
}
.modal .modal-fixed-footer .modal-body {
  position: absolute;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow-y: auto;
}
.modal .modal-fixed-footer .modal-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
}
.modal .change-password.modal-dialog h5, .modal .change-password.modal-dialog .h5 {
  padding: 0;
}
.modal .change-password.modal-dialog h5::after, .modal .change-password.modal-dialog .h5::after {
  left: 0;
  bottom: 0;
}
.modal .change-password.modal-dialog form {
  margin: 30px 0px;
}
.modal .change-password.modal-dialog form .row {
  margin-bottom: 10px;
}
.modal .change-password.modal-dialog form .input-field {
  position: relative;
}
.modal .change-password.modal-dialog form .input-field input {
  padding: 15px 10px 0 15px !important;
  margin: 0;
  background-color: #f7ffe8;
  border: 1px solid #e0efc8;
  border-radius: 15px;
  border-bottom: 1px solid #e0efc8 !important;
  margin-bottom: 0 !important;
  height: 55px !important;
  width: 100%;
  outline: none;
  margin: 0 auto;
}
.modal .change-password.modal-dialog form .input-field label {
  position: absolute;
  top: 5px;
  left: 15px;
  color: #595959;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.modal .change-password.modal-dialog .submit-btn {
  margin-bottom: 0;
  margin-top: 7px;
  width: 100%;
  justify-content: right;
  padding: 0;
}
.modal .change-password.modal-dialog a.red:hover::after {
  background-color: #a21c12 !important;
  transition: all ease-in 0.4s;
  right: 0;
}
.modal .change-password.modal-dialog a.green:hover::after {
  background-color: #5dae32 !important;
  transition: all ease-in 0.4s;
  right: 0;
}
.modal .change-password.modal-dialog a.btn {
  position: relative;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  max-width: 110px;
  float: right;
  height: 38px;
  display: block;
  color: #fff !important;
  line-height: 2;
  font-size: 12px;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 600;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  overflow: inherit;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.modal .change-password.modal-dialog a.btn {
  right: 0;
}
.modal .change-password.modal-dialog a.btn:hover::before {
  opacity: 0;
}
.modal .red.btn {
  background-color: #f44336 !important;
}
.modal .green.btn {
  background-color: #328708 !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.change-password.modal-dialog.modal-fixed-footer {
  max-height: 385px;
  max-width: 470px;
  height: 60% !important;
}

.modal .modal-content a.modal-close {
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  top: 13px;
  right: 15px;
  z-index: 9;
}
.modal .modal-content h5, .modal .modal-content .h5 {
  position: relative;
  margin: 0;
  padding: 10px 10px 5px 15px;
  color: #fff;
  font-size: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 25px;
  font-weight: 600;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal .modal-content h5::after, .modal .modal-content .h5::after {
  content: "";
  position: absolute;
  width: 95%;
  bottom: 6px;
  left: -14px;
  border-bottom: 2px solid #5dae32;
}
.modal .modal-footer {
  padding: 4px 10px;
  border-radius: 0 0 5px 6px;
}
.modal .input-field input {
  border: none;
  padding: 5px 0 0 5px !important;
  border-bottom: 1px solid transparent !important;
  color: #000;
  display: block;
  width: 100%;
  height: 30px !important;
  font-size: 12px !important;
  font-weight: 600;
  line-height: 1.5;
  border-radius: 0;
  margin-bottom: 10px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: none !important;
  transition: none !important;
}
.modal .modal-footer {
  background-color: #191E38;
  padding: 4px 6px;
  height: 56px;
  width: 100%;
  text-align: right;
  border: none;
}
.modal .modal-footer .submit-btn {
  padding: 0;
  margin: 0;
}
.modal .modal-footer .submit-btn .btn {
  width: auto;
  height: 34px;
  min-width: 110px;
}

.toggle_icon {
  position: relative;
}

.fancy_info-popup.hide-info {
  display: none;
}

.fancy_info-popup.show-info {
  display: block;
}

.drop_up.text-right.toggle_icon .fancy_info-popup.show-info {
  right: 56px;
}

.promoting_tabs.reporting_tabs {
  padding: 0;
  margin: 0;
}

.fancy_info-popup {
  min-width: 140px;
  width: max-content;
  padding: 5px 8px 5px 8px;
  line-height: 16px;
  top: -12px;
  right: 45px;
  background-color: #fff;
  border-radius: 8px;
  align-items: flex-start;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
}
.fancy_info-popup .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.6rem 0;
  position: absolute;
}
.fancy_info-popup .arrow:before, .fancy_info-popup .arrow:after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.fancy_info-popup .arrow:after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.fancy_info-popup p {
  padding: 2px 0;
  font-weight: 600;
  font-size: 10px;
  margin: 0;
  line-height: 14px;
  color: #7e7e7e;
}
.fancy_info-popup p span {
  display: inline-block;
  color: #000;
  font-weight: 700;
  opacity: 0.8;
}

.promotion-tab {
  background-color: #fff;
  border-radius: 12px;
  margin-bottom: 10px;
  padding-bottom: 1px;
}
.promotion-tab ul.tabs {
  height: 40px;
  justify-content: center;
  display: flex;
  background-color: #262D50;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.promotion-tab .tab_content {
  margin: 8px;
  padding: 0;
  display: block;
  height: 100%;
  overflow: auto;
}

.ng-dropdown-panel.ng-select-top, .ng-dropdown-panel {
  background-color: #555972;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.ng-dropdown-panel.ng-select-top .ng-option span.ng-option-label, .ng-dropdown-panel .ng-option span.ng-option-label {
  font-size: 12px;
  line-height: 34px;
  display: block;
  padding: 0 10px;
}
.ng-dropdown-panel.ng-select-top .ng-option:hover, .ng-dropdown-panel .ng-option:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.ng-value, .ng-select .ng-clear-wrapper {
  color: #fff;
}

.light_mode_on .ng-value, .light_mode_on .ng-select .ng-clear-wrapper {
  color: #000;
}

.ng-select-container.ng-has-value .ng-placeholder {
  display: none;
}

.ng-select-container.ng-has-value .ng-value-container:after {
  display: none;
}

.withdraw-bank .ng-value-container:after {
  content: "";
  position: absolute;
  right: 0;
  font-family: fontawesome;
  color: #000;
  font-size: 16px;
}

.main-section .left_sidebar {
  margin-top: 10px;
}

.datepicker_input input, .datepicker_input select, .datepicker_input .form-select {
  border: 1px solid #7076A1 !important;
  background: transparent !important;
  color: #ccc !important;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 13px;
  height: 40px;
  margin: 0;
}

.datepicker_input .form-select input {
  border: none !important;
  height: 35px !important;
}

.datepicker_input .search {
  background-image: linear-gradient(to top, #05AD34, #8EF50B);
  border: 1px solid #00FF47;
  width: 140px;
  line-height: 38px;
  border-radius: 4px;
  display: block;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.datepicker_input .open_bet_row {
  position: relative;
}

.datepicker_input .kyc-dropdown:after, .datepicker_input .open_bet_row:after {
  content: "";
  position: absolute;
  right: 10px;
  font-family: fontawesome;
  bottom: 10px;
  font-size: 14px;
  color: #ccc;
}

.datepicker_input .kyc-dropdown, .datepicker_input .open_bet_row {
  margin: 0 0 15px;
  width: calc(100% - 15px);
  position: relative;
}

popover-container, .popover {
  background-color: #535776 !important;
  border: 1px solid #535776 !important;
  overflow: hidden;
  position: absolute;
}

.popover-arrow.arrow {
  display: none !important;
}

.ng-select .ng-clear-wrapper {
  line-height: 36px;
  width: 30px !important;
  text-align: left;
}

.footer {
  background: #191E38;
  padding-top: 30px;
}
.footer .third_foot p.pay-title {
  margin: 0 0 20px;
  font-size: 18px;
}

.stake .input_field {
  margin-bottom: 0;
  width: 46%;
  display: inline-block;
  margin-left: 2%;
  margin-right: 2%;
}

.datepicker_input button.btn.btn-default.btn-secondary.btn-sm.pull-left.float-left, .datepicker_input .btn-group-sm > button.btn.btn-default.btn-secondary.pull-left.float-left, .date_picker button.btn.btn-default.btn-secondary.btn-sm.pull-left.float-left, .date_picker .btn-group-sm > button.btn.btn-default.btn-secondary.pull-left.float-left {
  width: 36px;
  background-color: #262D50;
  color: #ccc;
  border-radius: 0;
}
.datepicker_input button.btn.btn-default.btn-secondary.btn-sm.pull-right.float-right, .datepicker_input .btn-group-sm > button.btn.btn-default.btn-secondary.pull-right.float-right, .date_picker button.btn.btn-default.btn-secondary.btn-sm.pull-right.float-right, .date_picker .btn-group-sm > button.btn.btn-default.btn-secondary.pull-right.float-right {
  width: 36px;
  background-color: #262D50;
  color: #ccc;
  border-radius: 0;
}
.datepicker_input input, .date_picker input {
  outline: none;
  box-shadow: none !important;
}
.datepicker_input .popover-content.popover-body, .date_picker .popover-content.popover-body {
  padding: 0;
}
.datepicker_input .popover-content.popover-body daypicker table thead tr:first-child th button, .date_picker .popover-content.popover-body daypicker table thead tr:first-child th button {
  max-width: 100%;
}
.datepicker_input .popover-content.popover-body .card, .date_picker .popover-content.popover-body .card {
  box-shadow: none;
  background-color: transparent;
}
.datepicker_input .popover-content.popover-body .card .btn-secondary, .datepicker_input .popover-content.popover-body .card .btn, .date_picker .popover-content.popover-body .card .btn-secondary, .date_picker .popover-content.popover-body .card .btn {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
  padding: 0;
  background-color: #262D50;
  color: #ccc;
  border-radius: 0;
}
.datepicker_input .popover-content.popover-body .card .btn-secondary span, .datepicker_input .popover-content.popover-body .card .btn span, .date_picker .popover-content.popover-body .card .btn-secondary span, .date_picker .popover-content.popover-body .card .btn span {
  padding: 0;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff !important;
}
.datepicker_input .popover-content.popover-body .card table tr th, .date_picker .popover-content.popover-body .card table tr th {
  color: #ccc;
}
.datepicker_input .popover-content.popover-body .card table tr td, .date_picker .popover-content.popover-body .card table tr td {
  color: #fff;
}
.datepicker_input .popover-content.popover-body .card table tr td button.btn.btn-sm.btn-info span, .datepicker_input .popover-content.popover-body .card table tr td .btn-group-sm > button.btn.btn-info span, .date_picker .popover-content.popover-body .card table tr td button.btn.btn-sm.btn-info span, .date_picker .popover-content.popover-body .card table tr td .btn-group-sm > button.btn.btn-info span {
  background-color: #7076A1;
}
.datepicker_input .popover-content.popover-body timepicker, .date_picker .popover-content.popover-body timepicker {
  display: none;
}

button.btn.btn-sm.btn-info span, .btn-group-sm > button.btn.btn-info span {
  color: #fff !important;
  font-weight: bold;
  background-color: #72ab46;
  border-radius: 4px;
}

.home-page-footer .footer_bg, .home-page-footer .copy_right {
  display: none;
}

input:-internal-autofill-selected {
  background-color: unset !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f7ffe8 inset !important;
}

.toast-container {
  position: fixed !important;
}

body.modal-open {
  overflow: hidden;
}

.pass_field input.form_control {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
}
.pass_field i.hide {
  display: none;
}

.sports_icon img {
  height: 25px !important;
}

.icon_holder_small img {
  height: 25px !important;
}

.icon_holder_small .sports_icon img {
  height: 35px !important;
}

.accordion-button span img {
  height: 25px !important;
}

.menu_link span img {
  height: 25px !important;
}

.collapse_bet .custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.collapse_bet .cross_icon {
  width: 24px;
  text-align: right;
  margin-right: 5px;
}

.stack-edit dd.input_field input {
  padding-top: 0;
  height: 32px;
}

.open_bet_row .form-select {
  padding-top: 0;
  padding-bottom: 0;
}
.open_bet_row .form-select .ng-select-container.ng-has-value {
  line-height: 41px;
}
.open_bet_row .form-select span.ng-clear-wrapper span.ng-clear {
  vertical-align: middle;
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  max-width: 100%;
}

.main-body {
  overflow-x: hidden;
}

.main_navbar {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 8px;
  height: 52px;
  max-width: 100%;
}

.sa_col {
  width: 11%;
  margin-right: 5px;
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 6px 0;
}

.timeline {
  background: url(assets/images/timline.png);
  background-size: auto;
  color: #fff;
  background-repeat: no-repeat;
  position: relative;
  height: 268px;
}

.sa_col span {
  text-transform: capitalize;
  color: #fff;
  font-size: 16px;
}

.timeline span {
  color: #4C4F70;
  font-size: 40px;
  font-weight: 400;
}

.winner_client h6, .winner_client .h6 {
  margin: 0;
  color: #fff;
  text-transform: capitalize;
  font-weight: 100;
  font-size: 10px;
}

.spca_div {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
  /*  opacity: 0.4;*/
  box-shadow: 0px 4px 24px 1px rgba(35, 16, 94, 0.25);
  backdrop-filter: blur(22.5px);
  border-radius: 8px;
  width: 355px;
  margin: 0 auto;
  padding: 25px;
}

.spca_div p {
  color: #E4E4E4;
  font-size: 13px;
}

.section-header p {
  margin: 0 auto 30px;
  color: #ddd;
  font-size: 13px;
  font-weight: 400;
  width: 40%;
  text-align: center;
}

.section-header h2, .section-header .h2 {
  font-size: 25px;
  text-align: center;
}

.client_say p {
  color: #E4E4E4;
  font-size: 12px;
  font-weight: 400;
}

.client_say div h5, .client_say div .h5 {
  color: #fff;
  font-size: 11px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

.winner_titile h2 span, .winner_titile .h2 span {
  color: #fff;
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 300;
  margin-left: 15px;
}

.winner_client {
  padding: 25px 0px;
  border: 1px solid #262D50;
  border-radius: 6px;
  text-align: center;
  width: 140px;
}

.client_say {
  border: 1px solid #262D50;
  border-radius: 6px;
  padding: 40px;
}

.slider1 .swiper-button-next:after,
.slider1 .swiper-button-prev:after,
.slider2 .swiper-button-next:after,
.slider2 .swiper-button-prev:after,
.slider3 .swiper-button-next:after,
.slider3 .swiper-button-prev:after {
  font-size: 12px;
  color: #fff;
}

.market .card-body .col-md-5.col-7 {
  padding: 0 5px 0 0;
}
.market .card-body .col-md-7.col-5 {
  padding: 0 0 0 5px;
}

.placebet .form-group:last-child {
  margin-left: 10px;
}

/*light_mode_on*/
body.light_mode_on {
  background: #fff;
}

.lightmodeimg {
  display: none;
}

.light_mode_on .dashboard_tabcontent .card-header {
  background: #C9D5E1;
}
.light_mode_on .socialIcon .closeIcon i {
  color: #C9D5E1;
}
.light_mode_on .search-top .dropdown-menu.show {
  background-color: #e9eef4;
  border: 1px solid #e9eef4;
}
.light_mode_on .search-top .dropdown-menu.show .dropdown-item {
  color: #000;
  border-bottom: 1px solid #e9eef4;
}
.light_mode_on .search-top .dropdown-menu.show .dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.light_mode_on .matchDetail-header {
  background: #F8F8FA;
  border: 1px solid #E7EDF3;
}
.light_mode_on .promoting_tabs {
  background: transparent;
  padding: 0 !important;
}
.light_mode_on .promoting_tabs .nav-link {
  background-color: #E8EEF4 !important;
  color: #000;
  margin-top: 0;
}
.light_mode_on .promoting_tabs .nav-link span {
  color: #000;
}
.light_mode_on .promoting_tabs .nav-link svg path {
  fill: #000;
}
.light_mode_on .promoting_tabs .nav-link:hover {
  color: #fff !important;
  background-image: linear-gradient(to top, #05AD34, #8EF50B) !important;
}
.light_mode_on .promoting_tabs .nav-link:hover svg path {
  fill: #fff;
}
.light_mode_on .promoting_tabs .nav-link:hover span {
  color: #fff;
}
.light_mode_on .promoting_tabs .nav-link.active {
  color: #fff !important;
  background-color: #5dae32 !important;
}
.light_mode_on .promoting_tabs .nav-link.active svg path {
  fill: #fff;
}
.light_mode_on .promoting_tabs .nav-link.active i {
  color: #fff;
}
.light_mode_on .coundown_number h6, .light_mode_on .coundown_number .h6, .light_mode_on .coundown_number span {
  color: #0F1228;
  border: 1px solid #8c9aa9;
}
.light_mode_on .dropdown.userMenus.hide-on-mobile a {
  color: #0F1228;
}
.light_mode_on .dropdown.userMenus.hide-on-mobile .dropdown-menu {
  background-color: #E8EEF4;
}
.light_mode_on .dropdown.userMenus.hide-on-mobile .dropdown-menu a {
  border-bottom: 1px solid #C9D5E1 !important;
  color: #000;
}
.light_mode_on .dropdown.userMenus.hide-on-mobile .dropdown-menu a img {
  filter: invert(1);
}
.light_mode_on .dropdown.userMenus.hide-on-mobile .dropdown-menu .user_header {
  background: #F8F8FA;
}
.light_mode_on .dropdown.userMenus.hide-on-mobile .dropdown-menu .user_header small, .light_mode_on .dropdown.userMenus.hide-on-mobile .dropdown-menu .user_header .small {
  color: #000;
}
.light_mode_on .bankList-table table {
  width: 100%;
}
.light_mode_on .bankList-table table thead tr th {
  color: #0F1228 !important;
}
.light_mode_on .bankList-table table tbody tr td {
  color: #000 !important;
}
.light_mode_on .bankList-table table tbody tr:hover, .light_mode_on .bankList-table table tbody tr:nth-child(odd) {
  background-color: #b1bdca !important;
}
.light_mode_on .add-bank, .light_mode_on .withdraw-amount {
  color: #000 !important;
}
.light_mode_on .user_col {
  background: #E8EEF4;
}
.light_mode_on .bottom_discription {
  background-color: #fff;
}
.light_mode_on .bottom_discription .title {
  color: #000;
}
.light_mode_on .number {
  color: #000;
}
.light_mode_on .number svg path {
  fill: #000;
}
.light_mode_on .top-content span, .light_mode_on .bonus-subscription h4, .light_mode_on .bonus-subscription .h4, .light_mode_on .turnover-date {
  color: #0F1228;
}
.light_mode_on .bonus-subscription span {
  color: #000;
}
.light_mode_on .total-turnover {
  color: #3e970e;
}
.light_mode_on .data_table th, .light_mode_on .dataTables_length label, .light_mode_on .dataTables_length select {
  color: #000;
}
.light_mode_on .dataTable td {
  background-color: #E7EDF3;
}
.light_mode_on .dataTable tbody tr:nth-child(odd) td, .light_mode_on .dataTable tbody tr:hover td {
  background-color: #b1bdca;
}
.light_mode_on .dataTable tbody tr td {
  color: #000;
  border-bottom: 1px solid #C9D5E1 !important;
}
.light_mode_on td .discription {
  color: #02091a;
}
.light_mode_on table.dataTable.no-footer {
  border-bottom: none;
}
.light_mode_on .modal .modal-content h5, .light_mode_on .modal .modal-content .h5 {
  color: #000;
}
.light_mode_on .modal .modal-footer, .light_mode_on .modal .close-icon {
  background-color: #b1bdca;
}
.light_mode_on .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content {
  background-color: #E7EDF3;
}
.light_mode_on .manual-deposit.modal-dialog .modal-body .body-inner .payment-footer p,
.light_mode_on .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .pay-details .title {
  color: #000;
}
.light_mode_on .minMax-limit p {
  color: #000;
}
.light_mode_on .manual-deposit.modal-dialog .modal-body .body-inner .payment-method .bank-transfer .utr-amount-details .right-content .upload_ss {
  background-color: #fff;
}
.light_mode_on .date-title, .light_mode_on .date-details span {
  color: #000;
}
.light_mode_on .matchDetail-header .inner-right .header-icon ul li a img {
  filter: invert(1);
}
.light_mode_on .burger_menu img {
  filter: invert(0);
}
.light_mode_on .main_left_sidebar {
  background-color: #E7EDF3;
}
.light_mode_on .bottom_tabs .dropdown-menu.avtar-dropdow {
  background-color: #E7EDF3 !important;
  border: none;
  border-radius: 0;
}
.light_mode_on .bottom_tabs .dropdown-menu.avtar-dropdow li a {
  color: #000 !important;
}
.light_mode_on .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .light_mode_on .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .light_mode_on .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #000 !important;
}

.light_mode_on .lightmodeimg {
  display: inline-block;
}

.light_mode_on .darkmodeimg {
  display: none;
}

.light_mode_on .main_navbar {
  background: #E8EEF4;
  border: 1px solid #fff;
}

.light_mode_on .navbar a.active {
  color: #fff;
}

.light_mode_on .navbar a, .light_mode_on .navbar a:not([href]):not([class]),
.light_mode_on .navbar a:focus {
  color: #000;
}

.light_mode_on .slide a.carousel-control-prev span,
.light_mode_on .slide a.carousel-control-next span {
  filter: invert(1);
}

.light_mode_on .carousel-indicators button {
  background: #ddd;
}

.light_mode_on .section-header p {
  color: #8D8D8D;
}

.light_mode_on .timeline p {
  color: #939393;
}

.light_mode_on .timeline span {
  color: #E2E2E2;
}

.light_mode_on .winner_titile h2 span, .light_mode_on .winner_titile .h2 span {
  color: #B1B1B1;
}

.light_mode_on .text-light {
  color: #000 !important;
}

.light_mode_on .winner_client {
  border: 1px solid #B6C5D5;
}

.light_mode_on .winner_client h6, .light_mode_on .winner_client .h6 {
  color: #AEAEAE;
}

.light_mode_on .winner_section,
.light_mode_on .spca2 {
  background: #E7EDF3;
}

.light_mode_on .spca2 .spca_div {
  background: #fff;
}

.light_mode_on .slider1 .swiper-button-next:after,
.light_mode_on .slider1 .swiper-button-prev:after,
.light_mode_on .slider2 .swiper-button-next:after,
.light_mode_on .slider2 .swiper-button-prev:after,
.light_mode_on .slider3 .swiper-button-next:after,
.light_mode_on .slider3 .swiper-button-prev:after {
  color: #000;
}

.light_mode_on .slider1 .swiper-pagination-bullet,
.light_mode_on .slider2 .swiper-pagination-bullet,
.light_mode_on .slider3 .swiper-pagination-bullet {
  background: #111;
}

.light_mode_on .spca_div {
  background: #E7EDF3;
  box-shadow: none;
}

.light_mode_on .spca_div p {
  color: #000;
}

.light_mode_on .client_say {
  border: 1px solid #E7EDF3;
}

.light_mode_on .client_say p {
  color: #8D8D8D;
}

.light_mode_on .footer {
  background: #1F1F1F;
}

.light_mode_on .footer-bottom {
  background: #121212;
}

.light_mode_on .client_say div h5, .light_mode_on .client_say div .h5 {
  color: #000;
}

.light_mode_on .timeline {
  background: url(assets/images/timline-light.png);
}

.light_mode_on .sa_col {
  background: #E8EEF4;
  border: 1px solid #E2F1FF;
}

.light_mode_on .sa_col span {
  color: #000;
}

.light_mode_on ul.dashboard_navpills li a.nav-link {
  background: #E8EEF4;
  border: 1px solid #E2F1FF;
  color: #191E38;
}

.light_mode_on .input-calednar {
  border-bottom: 1px solid #C9D5E1;
}

.light_mode_on .left_side_bar,
.light_mode_on .scoarecard,
.light_mode_on .detail_data .nav-pills,
.light_mode_on .userMenus .dropdown-menu,
.light_mode_on .terms_div,
.light_mode_on .user_profile .row .cm3,
.light_mode_on .user_profile .row .cm9,
.light_mode_on .withdrawal_div p,
.light_mode_on .withdrawal_div form,
.light_mode_on .filter_form,
.light_mode_on .openbets_div h2 a,
.light_mode_on .openbets_div .h2 a,
.light_mode_on .casino_slider,
.light_mode_on form.stakes_div {
  background: #E8EEF4;
  border: 1px solid #fff;
}

.light_mode_on .userMenus .dropdown-menu a {
  border-color: #fff;
}

.light_mode_on .reporting_tabs .date_picker, .light_mode_on .reporting_tabs button.filter-btn {
  background-color: #E8EEF4;
  border: 1px solid #F8F8FA;
}
.light_mode_on .reporting_tabs table.dataTable {
  border: 1px solid #C9D5E1 !important;
}
.light_mode_on .reporting_tabs table.dataTable thead tr th {
  background-color: #C9D5E1;
  color: #000;
}
.light_mode_on .reporting_tabs table.dataTable tbody tr td {
  background-color: #E8EEF4;
  color: #000;
}
.light_mode_on .withdraw-bank .bank-dropdown, .light_mode_on .withdraw-bank .bank-ac-name .input-field,
.light_mode_on .withdraw-bank .bank-account-no .input-field, .light_mode_on .withdraw-bank .bank-ifsc .input-field,
.light_mode_on .bank-amount {
  border: 1px solid #C9D5E1 !important;
}
.light_mode_on .withdraw-bank .bank-dropdown input, .light_mode_on .withdraw-bank .bank-ac-name .input-field input,
.light_mode_on .withdraw-bank .bank-account-no .input-field input, .light_mode_on .withdraw-bank .bank-ifsc .input-field input,
.light_mode_on .bank-amount input {
  color: #000 !important;
}
.light_mode_on .ng-dropdown-panel.ng-select-top, .light_mode_on .ng-dropdown-panel {
  background-color: #e9eef4;
  color: #000;
}
.light_mode_on .datepicker_input input, .light_mode_on .datepicker_input select, .light_mode_on .datepicker_input .form-select {
  border: 1px solid #C9D5E1 !important;
  color: #000 !important;
}
.light_mode_on .datepicker_input .kyc-dropdown:after, .light_mode_on .datepicker_input .open_bet_row:after {
  color: #000;
}
.light_mode_on .datepicker_input .form-select input {
  border: none !important;
}
.light_mode_on .left_side_bar {
  background-color: #fff;
  border: 1px solid #E8EEF4;
}
.light_mode_on .list_menu .eventleague {
  background-color: transparent !important;
}
.light_mode_on .backToTop {
  color: #fff;
}

.light_mode_on .left_side_bar ul li a,
.light_mode_on .sports_menus,
.light_mode_on .left_side_bar .nav-tabs,
.light_mode_on .user_header,
.light_mode_on .terms_div .accordion-button,
.light_mode_on .profile_account .nav-pills a,
.light_mode_on .list_menu {
  background: #F8F8FA !important;
  color: #0F1228;
}

.light_mode_on .h1x1 button,
.light_mode_on .event_lists p,
.light_mode_on .left_side_bar .nav-tabs a.nav-link,
.light_mode_on h3.page_title,
.light_mode_on .page_title.h3,
.light_mode_on .dashboard_tabcontent .card-header a,
.light_mode_on .score p span small,
.light_mode_on .score p span .small,
.light_mode_on .score h3,
.light_mode_on .score .h3,
.light_mode_on .score h5,
.light_mode_on .score .h5,
.light_mode_on .market .card-header span em,
.light_mode_on .market .card-header,
.light_mode_on .event_name,
.light_mode_on .market .card-body .row,
.light_mode_on .betting_sidebar ul.nav a,
.light_mode_on .abl_radios [type=radio]:checked + label,
.light_mode_on .placebet .input-group-text,
.light_mode_on .user_header div small,
.light_mode_on .user_header div .small,
.light_mode_on .table_theme tbody tr td,
.light_mode_on .modal .modal-content .modal-header h5,
.light_mode_on .modal .modal-content .modal-header .h5,
.light_mode_on .modal .modal-content .modal-header .close,
.light_mode_on .terms h2,
.light_mode_on .terms .h2,
.light_mode_on .terms_div p,
.light_mode_on .user_profile table th,
.light_mode_on .user_profile table td,
.light_mode_on .bstd div span,
.light_mode_on .list-group-item .withdrawal_div form label,
.light_mode_on .withdrawal_div form small,
.light_mode_on .withdrawal_div form .small,
.light_mode_on .withdrawal_div form label,
.light_mode_on .bank_aba em,
.light_mode_on .bank_aba p,
.light_mode_on .bank_aba p span,
.light_mode_on .bank_aba p span small,
.light_mode_on .bank_aba p span .small,
.light_mode_on .filter_form input,
.light_mode_on .filter_form select,
.light_mode_on .openbets_div h2,
.light_mode_on .openbets_div .h2,
.light_mode_on .settled_div h2,
.light_mode_on .settled_div .h2,
.light_mode_on .stakes_div .form-group label,
.light_mode_on .stakes_div h2,
.light_mode_on .stakes_div .h2,
.light_mode_on .stakes_div .form-group input {
  color: #0F1228;
}

.light_mode_on .abl_radios [type=radio]:not(:checked) + label {
  color: #9FAAB5;
}

.light_mode_on .user_profile table tr,
.light_mode_on .stakes_div h2,
.light_mode_on .stakes_div .h2 {
  border-bottom: 1px solid #c9d5e1;
}

.light_mode_on .stakes_div .form-group input {
  background: #fff;
  border: none;
}

.light_mode_on .cm9 p,
.light_mode_on .bg-theme,
.light_mode_on .bstd,
.light_mode_on .bank_aba,
.light_mode_on .filter_form input,
.light_mode_on .filter_form select {
  background: #c9d5e1;
}

.light_mode_on .dashboard_tabcontent .card-header,
.light_mode_on .market .card-header,
.light_mode_on .betting_sidebar ul.nav,
.light_mode_on .modal .modal-content .modal-header,
.light_mode_on .table_theme thead tr th {
  color: #0F1228;
}

.light_mode_on .market .card-body {
  background: #F8F8FA;
  border: 1px solid #E7EDF3;
}

.light_mode_on .placebet .form-group label img,
.light_mode_on .terms_div .accordion-button img,
.light_mode_on .cm9 p span img,
.light_mode_on .bstd div img {
  filter: invert(1);
}

.light_mode_on .placebet .input-group-text {
  background: #C9D5E1;
}

.light_mode_on .market .card,
.light_mode_on .dashboard_tabcontent .card,
.light_mode_on .market .card-body .row,
.light_mode_on .betting_sidebar,
.light_mode_on .modal .modal-content {
  background: #F8F8FA;
  border: 1px solid #E7EDF3;
  box-shadow: none;
}

.light_mode_on .market .card-body .row,
.light_mode_on .filter_form input,
.light_mode_on .filter_form select {
  border: none;
}

.light_mode_on .market .card-header span em {
  background: #C9D5E1;
}

.light_mode_on .dashboard_list {
  background: #fff;
  border: 1px solid #E7EDF3;
  margin-bottom: 0;
  border-radius: 0;
  border-width: 0 0 1px;
}

.light_mode_on .oods_boxes button {
  color: #000;
}

.light_mode_on .elm_icons span,
.light_mode_on .event_lists em {
  background: #fff;
  border: 1px solid #E7EDF3;
  color: #0F1228;
}

.light_mode_on .terms_div .accordion-item {
  border: 1px solid #aebdcb;
}

.light_mode_on .event_lists p span {
  color: #01BD35;
}

.light_mode_on .detail_data .nav-pills li a {
  color: #000;
}

.light_mode_on .market .card-header span img {
  filter: invert(1);
}

.light_mode_on .market .card-body .row,
.light_mode_on .table_theme {
  border: 1px solid #C9D5E1;
}

.light_mode_on .table_theme tbody tr {
  border-bottom: 1px solid #C9D5E1;
}

.light_mode_on .market .card-body .row {
  background: #fff;
  border: 1px solid #E7EDF3;
}

@media (max-width: 767px) {
  .light_mode_on .timeline {
    background: none;
    height: auto;
  }

  .light_mode_on .navbar ul {
    background: #fff;
  }

  .light_mode_on.mobile-nav-active .navbar:before {
    background: #ddddddcf;
  }

  .content_area .left_side_bar {
    display: none;
  }

  .main_navbar {
    padding: 0 5px !important;
  }
}
/*light_mode_on*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
    position: relative;
    width: 70%;
    box-shadow: none;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .navbar li {
    position: relative;
  }

  .navbar > ul > li {
    white-space: nowrap;
  }

  .navbar a, .navbar a:not([href]):not([class]),
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 14px;
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    color: #fff;
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
    border-radius: 4px;
  }

  .navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover, .navbar a:not([href]):not([class]):hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    background: #01BD35;
    color: #fff;
    border: 0.5px solid #00FF47;
  }

  .navbar .dropdown a:hover:before,
.navbar .dropdown:hover > a:before,
.navbar .dropdown .active:before {
    visibility: hidden;
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    padding: 0 0 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    color: #fff;
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
  }

  .navbar .megamenu {
    position: static;
  }

  .navbar .megamenu ul {
    right: 0;
    padding: 10px;
    display: flex;
  }

  .navbar .megamenu ul li {
    flex: 1;
  }

  .navbar .megamenu ul li a,
.navbar .megamenu ul li:hover > a {
    background: none;
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}
@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
/*--------------------------------------------------------------
# Mobile Navigation
--------------------------------------------------------------*/
@media (max-width: 1279px) {
  .navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width: calc(100% - 100px);
    bottom: 0;
    transition: 0.3s;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 10px 0;
    margin: 0;
    background: #191e38;
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
  }

  .navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: #eee;
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #03bd34 !important;
  }

  .navbar .dropdown ul,
.navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid rgba(221, 221, 221, 0.3);
  }

  .navbar .dropdown > .dropdown-active,
.navbar .dropdown .dropdown > .dropdown-active {
    display: block;
  }

  .mobile-nav-toggle {
    cursor: pointer;
    position: fixed;
    top: 8px;
    z-index: 9;
    right: 15px;
    width: 35px;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  .mobile-nav-toggle img {
    width: 35px;
  }

  .mobile-nav-toggle:focus {
    box-shadow: none;
  }

  .mobile-nav-toggle.menu-close {
    padding: 0;
  }

  .mobile-nav-toggle.menu-close .openNav {
    display: none;
  }

  .mobile-nav-toggle.menu-close .closeNav {
    display: inline-block;
  }

  .mobile-nav-toggle .closeNav {
    display: none;
  }

  .mobile-nav-active {
    overflow: hidden;
    z-index: 9995;
    position: relative;
  }

  .mobile-nav-active .navbar {
    left: 0;
  }

  .mobile-nav-active .navbar:before {
    content: "";
    position: fixed;
    inset: 0;
    background: #ddd3;
  }

  .searchbar_input {
    display: none;
  }

  .login-btn.btn-getstarted {
    display: block !important;
    width: unset !important;
  }
}
/*dashboard*/
.dashboard_swiper {
  margin-bottom: 20px;
}

.content_area {
  margin-bottom: 15px;
}

h3.page_title, .page_title.h3 {
  color: #ccc;
  text-transform: capitalize;
  font-size: 24px;
}

.dashboard_navpills {
  position: relative;
  margin-bottom: 10px;
}

.input-calednar {
  position: absolute;
  right: 0;
  height: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid #262d50;
  color: #ccc;
  font-size: 13px;
  width: 150px;
}

.input-calednar:focus {
  outline: none;
}

ul.dashboard_navpills li a.nav-link,
ul.dashboard_navpills li a.nav-link.active {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 8px;
  color: #ccc;
  font-size: 16px;
  text-transform: capitalize;
  margin-right: 10px;
}

.h1x1 {
  width: 100%;
  display: flex;
}

.h1x1 button {
  width: 33%;
  border: none;
  background: none;
  color: #ccc;
  font-size: 13px;
}

.dashboard_tabcontent .card {
  background: #191E38;
  border: none;
  border-radius: 0;
}

.dashboard_tabcontent .card-header {
  background: #262D50;
  position: relative;
  border: none;
  color: #fff;
}

.dashboard_tabcontent .card-header a img {
  position: absolute;
  right: 0;
  top: 0;
}

.dashboard_tabcontent .card-header a {
  display: block;
  line-height: 1.8;
  color: #ccc;
  font-size: 18px;
  text-transform: capitalize;
}

.dashboard_list {
  align-items: center;
  border: 1px solid #262D50;
  border-radius: 8px;
  margin-bottom: 12px;
}

.card-body .dashboard_list:last-child {
  margin-bottom: 0;
}

.event_lists {
  display: flex;
  align-items: center;
  padding: 2px 0;
  cursor: pointer;
}

.dashboard_tabcontent .card-body {
  padding-top: 5px;
  padding-bottom: 8px;
}

.event_lists button {
  background: #01BD35;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  border: none;
  padding: 0 15px;
  line-height: 46px;
}

.event_lists em {
  background: #262D50;
  border-radius: 100px;
  font-size: 10px;
  color: #ccc;
  padding: 3px 6px;
  font-style: normal;
  text-transform: capitalize;
  margin-left: 0;
}

.event_lists p {
  margin: 0;
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
}

.event_lists p span {
  color: #FFDE00;
  font-size: 16px;
  margin: 0 3px;
}

.elm_icons {
  display: flex;
}

.elm_icons span {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 2px;
  position: relative;
  padding: 5px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  margin-right: 7px;
}

.elm_icons span em {
  font-style: normal;
  position: absolute;
  top: -7px;
  right: -5px;
  width: 16px;
  height: 16px;
  background: #01BD35;
  display: flex;
  font-size: 10px;
  border-radius: 50px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.back {
  background: #83B9EA;
}

.lay {
  background: #EEADBA;
}

.oods_boxes {
  display: flex;
  width: 100%;
}

.oods_boxes .ob {
  width: 33%;
  text-align: center;
}

.oods_boxes .ob button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.oods_boxes .ob button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.oods_boxes button {
  border: none;
  color: #fff;
  font-size: 14px;
  width: 40%;
  border-radius: 5px;
  height: 36px;
  vertical-align: middle;
  line-height: initial;
}

.mo_header_1x1 {
  display: none;
}

/*dashboard*/
/*detail*/
.scoarecard {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 8px;
  padding: 5px;
}

.scoarecard .row {
  align-items: center;
}

.score {
  text-align: right;
}

.score p {
  margin: 0;
  display: inline-flex;
}

.score p em {
  font-style: normal;
  background: #FF0000;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  height: 30px;
  display: inline-flex;
  align-items: center;
  padding: 0 20px;
  margin-right: 5px;
}

.score p span {
  text-align: left;
  line-height: normal;
}

.score p span small, .score p span .small {
  font-size: 12px;
  color: #fff;
  display: block;
}

.score h3, .score .h3 {
  font-size: 26px;
  color: #fff;
  margin: 0;
}

.score h5, .score .h5 {
  color: #ccc;
  font-size: 16px;
  margin: 0;
}

.detail_data .nav-pills {
  width: auto;
  background: #191E38;
  border: 1px solid #191E38;
  border-radius: 8px;
  display: inline-flex;
  padding: 5px;
  margin: 0px 0 10px !important;
}

.detail_data .nav-pills li a {
  border-radius: 8px;
  color: #fff;
  text-transform: capitalize;
  width: 145px;
  text-align: center;
  height: 36px;
  display: flex;
  line-height: normal;
  justify-content: center;
}

.detail_data .nav-pills li a.active {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
}

.market .card {
  border: none;
  background: #191E38;
  color: #fff;
  margin-bottom: 5px;
}

.market .card-header {
  background: #262D50;
  color: #ccc;
  font-size: 16px;
  border: none;
}

.market .card-header span {
  float: right;
}

.market .card-header small, .market .card-header .small {
  font-size: 10px;
  color: #7076A1;
  vertical-align: middle;
}

.market .card-header span em {
  font-style: normal;
  font-size: 12px;
  background: #191E38;
  border-radius: 4px;
  padding: 10px;
  display: inline;
}

.market .card-body {
  /*  padding-top: 0;
    padding-bottom: 0;*/
}

.loss {
  color: red !important;
}

.profit {
  color: green !important;
}

.odds_head button {
  background: none;
}

.odds_head .back {
  background: #83B9EA;
  text-transform: capitalize;
  font-size: 14px;
}

.odds_head .lay {
  background: #EEADBA;
  text-transform: capitalize;
  font-size: 14px;
}

.bet {
  color: #01BD35;
  text-transform: capitalize;
  box-shadow: inset 0 0 0 1px #01BD35;
}

.event_name {
  line-height: normal;
  font-size: 18px;
  text-transform: capitalize;
}

.fancy_market .event_name {
  font-size: 15px;
}

.fancy_market .event_name span {
  display: inline-block;
}

.fancy_market .bet {
  background: none;
  border: none;
  padding: 4px 15px;
  border-radius: 4px;
}

.event_name p {
  margin-bottom: 0;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.event_name span {
  display: inline-block;
  font-size: 12px;
}

.odds_section {
  width: 100%;
  display: flex;
}

.odds_section button {
  border: none;
  margin: 0 1px;
  border-radius: 4px;
  line-height: normal;
  height: 41px;
  font-size: 12px;
  width: 16.666%;
}

.fancy_market .odds_section button {
  width: 50%;
}

.odds_section button span {
  display: block;
}

.back1 {
  background: #D4EDFF;
}

.lay1 {
  background: #D4EDFF;
}

.market .card-body .row {
  border: 1px solid #262d50;
  padding: 2px 4px;
  align-items: center;
}

.market .card-body .row:nth-child(1) {
  border: none;
}

.market .card-body .row:nth-child(2) {
  border-bottom: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.market .card-body .row:nth-child(3) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.suspended {
  position: relative;
}

.suspended:before {
  content: "SUSPENDED";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #191E38;
  background: #D4EDFF;
  border-radius: 4px;
  font-size: 12px;
}

/*detail*/
/*place bet*/
.placebet {
  background: #262D50;
  border-radius: 0;
}

.placebet p {
  margin: 0;
  font-size: 14px;
  text-align: right;
}

.placebet .loss {
  color: #FFB7C9;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.placebet .profit {
  color: #C5FFBB;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stake-group {
  display: flex;
  background: #fff;
}

.placebet .stake-group input {
  width: 70px;
}

.placebet .form-group input {
  width: 80px;
  border: none;
  text-align: center;
  height: 32px;
}

.placebet .form-group input:focus {
  outline: none;
}

.placebet .form-group label {
  font-size: 17px;
  margin-right: 4px;
}

.placebet .input-group-text {
  padding: 0;
  border: none;
  color: #fff;
  background: #262D50;
  width: 25px;
  text-align: center;
  display: flex;
  border-radius: 2px;
  margin: 1px;
  justify-content: center;
}

.stakes {
  justify-content: end;
  display: flex;
  width: 100%;
  margin: 7px 0;
}

.stakes button {
  background: #191E38;
  border-radius: 2px;
  height: 34px;
  border: none;
  color: #fff;
  width: 10%;
  margin: 0 1px;
  font-size: 14px;
}

.ccp_btns {
  text-align: right;
}

.ccp_btns button {
  margin: 0 3px;
  padding: 0 15px;
}

.btn-cancel {
  height: 30px;
  background: #ee1909;
  border: 1px solid #ee1909;
  color: #fff;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 14px;
}

.btn-clear {
  height: 30px;
  background: #262D50;
  border: 1px solid #7076A1;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 14px;
  color: #7076A1;
}

.btn-placebet {
  height: 30px;
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border: 1px solid #2bb833;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 14px;
}

/*place bet*/
.table_theme {
  border: 1px solid #535776;
  margin-bottom: 0;
}

.table_theme thead tr th {
  color: #ccc;
  background: #191E38;
  font-size: 13px;
  text-transform: capitalize;
}

.table_theme tbody tr {
  border-bottom: 1px solid #535776;
}

.table_theme tbody tr td {
  font-size: 13px;
  color: #fff;
  text-transform: capitalize;
  vertical-align: middle;
}

.table_theme tbody tr td button {
  border: none;
  width: 55px;
  border-radius: 2px;
  text-transform: capitalize;
  font-size: 13px;
  padding: 3px;
}

.table_theme .lay {
  background: #FFDAF5;
}

.table_theme .back {
  background: #D5EDFF;
}

.modal .abl_radios {
  justify-content: start;
  margin-bottom: 10px;
}

.modal .abl_radios .custom-control {
  width: 10%;
}

/*modal*/
/*dataTables*/
.dataTables_wrapper {
  margin-top: 20px;
}

.dataTable td {
  background: #262D50;
}

.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_filter {
  display: none;
}

.dataTables_length select {
  background: #262D50;
  border: 1px solid #535776;
  border-radius: 0;
  color: #fff;
  margin: 0 4px;
}

.dataTables_length label {
  text-transform: capitalize;
  font-size: 13px;
  color: #fff;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}

.dataTables_paginate ul li {
  margin: 0 4px;
}

.dataTables_paginate ul .previous a,
.dataTables_paginate ul .next a {
  background: none !important;
  width: auto;
  border: none;
  color: #fff;
  font-size: 12px;
}

.dataTables_paginate ul li a {
  background: none !important;
  border: 1px solid #535776;
  height: 25px;
  width: 25px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  line-height: normal;
  padding: 0;
  text-align: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
}

.dataTables_paginate ul li.active a,
.dataTables_paginate ul li a:hover {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%) !important;
  border: 1px solid #262D50 !important;
  border-radius: 100px;
  color: #fff;
}

/*datatable*/
/*end datatable*/
/*cp*/
.cp_form {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.cp_form h2, .cp_form .h2 {
  color: #ccc;
  text-transform: capitalize;
  font-size: 20px;
}

.cp_form label {
  color: #83898C;
  font-size: 14px;
  text-transform: capitalize;
}

.cp_form input {
  height: 42px;
  border-radius: 4px;
}

.cp_form .form-group {
  margin-bottom: 15px;
  position: relative;
}

.cp_form .form-group .passwordeye {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.btn_save {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border: 1px solid #00FF47;
  border-radius: 2px;
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
  padding: 10px 52px;
}

/*cp*/
/*profile_account*/
.profile_account .nav-pills a {
  text-transform: capitalize;
  background: #262D50;
  border-radius: 4px;
  padding: 15px 25px;
  color: #fff;
  font-size: 13px;
  margin-right: 10px;
}

.profile_account .nav-pills a.active {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
}

.user_profile .row .cm3,
.user_profile .row .cm9 {
  background: #191E38;
  border-radius: 6px;
  padding: 15px;
}

.user_profile .row .cm3 img {
  margin: 0 auto;
  display: block;
}

.user_profile table th,
.user_profile table td {
  color: #ccc;
  vertical-align: middle;
  font-size: 14px;
  text-transform: capitalize;
  padding: 10px;
}

.user_profile table td {
  position: relative;
}

.user_profile table td img {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.user_profile .row .cm3 table {
  margin-top: 10px;
  margin-bottom: 0;
}

.user_profile table tr {
  border-bottom: 1px solid #262D50;
}

.user_profile table tr:last-child {
  border-bottom: none;
}

.cm9 p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #262D50;
  border-radius: 8px;
  padding: 8px;
}

.cm9 p span {
  font-size: 24px;
  text-transform: capitalize;
  color: #535776;
}

.cm9 p em {
  font-style: normal;
  font-size: 36px;
  color: #01BD35;
}

.cm9 table {
  border: none;
}

.cm9 table tr th {
  background: #262D50;
  padding: 12px;
  width: 50%;
}

.cm9 table tr td {
  padding: 12px;
  width: 50%;
}

.bg-theme {
  background: #262D50;
}

.cm9 table tfoot td {
  font-size: 20px;
}

.bstd {
  background: #191E38;
  border-radius: 6px;
  padding: 15px;
}

.bstd div {
  display: inline-flex;
  align-items: center;
}

.bstd div span {
  color: #fff;
  margin-left: 10px;
}

.bstd div small, .bstd div .small {
  display: block;
  color: #535776;
  font-size: 13px;
}

.bstd div h3, .bstd div .h3 {
  margin: 0;
}

.withdrawal_div p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #262D50;
  border-radius: 4px;
  padding: 15px;
}

.withdrawal_div p span {
  color: #01BD35;
  font-size: 20px;
}

.withdrawal_div .btn_addbank {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border-radius: 4px;
  color: #fff;
  padding: 10px 35px;
  border: none;
}

.withdrawal_div form {
  background: #262D50;
  border-radius: 6px;
  width: 50%;
  margin: 0 auto;
  padding: 25px;
}

.withdrawal_div form label {
  color: #ccc;
  font-size: 22px;
  line-height: normal;
}

.withdrawal_div form small, .withdrawal_div form .small {
  color: #ccc;
  font-size: 12px;
  display: block;
}

.withdrawal_div form input {
  width: 100%;
  height: 64px;
  text-align: center;
  border: none;
}

input:focus {
  outline: none;
}

.withdrawal_div h1, .withdrawal_div .h1 {
  font-size: 16px;
  color: #fff;
}

.bank_aba {
  border: 1px solid #01BD35;
  border-radius: 4px;
  padding: 20px;
}

.btn_wl {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border: 1px solid #00FF47;
  border-radius: 4px;
  color: #fff;
  padding: 20px 35px;
  font-size: 16px;
  text-transform: capitalize;
  margin-top: 15px;
}

.bank_aba em {
  display: block;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  letter-spacing: 5px;
  margin-top: 8px;
}

.bank_aba p {
  display: flex;
  justify-content: space-between;
  background: none !important;
  border: none !important;
  color: #fff;
  margin: 0;
  padding: 0;
  margin-top: 25px;
}

.bank_aba p span {
  color: #fff;
  line-height: normal;
  font-size: 18px;
}

.bank_aba p span small, .bank_aba p span .small {
  display: block;
  line-height: normal;
  color: #ccc;
  font-size: 12px;
}

.openbets_div h2, .openbets_div .h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #ccc;
  margin: 0;
  text-transform: capitalize;
}

.openbets_div h2 a, .openbets_div .h2 a {
  color: #01BD35 !important;
  font-size: 16px;
  background: #262D50;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #535776;
  border-radius: 6px 6px 0px 0px;
  padding: 9px 24px;
}

.filter_form {
  padding: 14px 18px;
  background: #262D50;
  border-radius: 6px 0px 6px 6px;
}

.filter_form .row .col-md-3 {
  padding: 0 3px;
  margin-bottom: 6px;
}

.filter_form input,
.filter_form select {
  padding: 6px 10px;
  background: #262D50;
  color: #ccc;
  font-size: 13px;
  width: 100%;
  height: 36px;
  border: 1px solid #535776;
}

.btn_apply {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border: 1px solid #00FF47;
  border-radius: 4px;
  text-transform: capitalize;
  color: #fff;
  height: 36px;
  width: 100%;
}

.openbets_div table {
  margin-top: 20px;
}

.openbets_div table th,
.openbets_div table td {
  padding: 10px;
}

.settled_div h2, .settled_div .h2 {
  color: #ccc;
  font-size: 20px;
  text-transform: capitalize;
}

.settled_div img {
  margin: 0 auto;
  display: block;
}

.casino_slider {
  background: #191E38;
  border-radius: 8px;
  padding: 20px 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.casino_slider img {
  width: 100%;
  height: auto;
  margin: 0 2px;
}

.casino_thumbs {
  margin: 0;
}

.casino_thumbs .col-md-4 {
  padding: 0 5px;
  margin-bottom: 10px;
}

.casino_thumbs img {
  width: 100%;
  max-height: auto;
}

.sw_history {
  background: url("assets/images/dw-history-bg.png");
  border-radius: 8px;
  padding: 15px;
  border: 2px solid #262D50;
}

.sw_history span {
  font-size: 20px;
  text-transform: capitalize;
  color: #fff;
  line-height: normal;
}

.sw_history span b {
  color: #FFDE00;
}

.sw_history div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sw_history div p {
  margin: 0;
  display: flex;
  align-items: center;
}

.sw_history div p span {
  font-size: 16px;
  color: #ccc;
  line-height: 1;
  margin-left: 5px;
}

.sw_history div p small, .sw_history div p .small {
  font-size: 13px;
  display: block;
}

button.btn_details {
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border-radius: 4px;
  padding: 5px 19px;
  border: none;
  color: #fff;
  text-transform: capitalize;
}

.terms_div {
  background: #262D50;
  border: 1px solid #535776;
  padding: 25px;
  position: relative;
}

.terms_div img.modalterms {
  position: relative;
  bottom: -25px;
}

.terms_div p {
  font-size: 13px;
  color: #ccc;
  text-align: justify;
}

.terms h2, .terms .h2 {
  color: #ccc;
  font-size: 20px;
  text-transform: capitalize;
}

.terms_div .accordion-button {
  background: #191E38;
  border-radius: 8px;
  box-shadow: none;
  color: #ccc;
  font-size: 13px;
}

.terms_div .accordion-button:after {
  display: none;
}

.terms_div .accordion-button img {
  margin-right: 10px;
}

.terms_div .accordion-item {
  border: 1px solid #191e38;
  background: none;
  margin-bottom: 10px;
  border-radius: 8px;
}

.terms_div a[aria-expanded=true] img {
  transform: rotate(90deg);
}

.terms_div .accordion-body {
  color: #ccc;
  font-size: 13px;
  padding: 10px;
}

.terms_div .btn_download {
  width: 50%;
}

.stakes_div h2, .stakes_div .h2 {
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
  border-bottom: 1px solid #535776;
  padding: 20px;
}

form.stakes_div {
  background: #191E38;
  /* dark/icon */
  border: 1px solid #535776;
  border-radius: 8px;
}

.stakes_div .row {
  padding: 20px;
}

.stakes_div .form-group label {
  display: block;
  color: #ccc;
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 2px;
  padding: 0;
}

.stakes_div .form-group input {
  width: 100%;
  background: #262D50;
  border: 1px solid #535776;
  border-radius: 4px;
  height: 45px;
  color: #fff;
  padding: 5px 10px;
  text-align: center;
}

.stakes_div .form-group {
  margin-bottom: 20px;
}

.stakes_div button {
  width: 100%;
  border: none;
  background: linear-gradient(358.32deg, #05AD34 -2.5%, #8EF50B 96.99%);
  border-radius: 6px;
  height: 51px;
  color: #fff;
  font-size: 22px;
  text-transform: capitalize;
}

/*profile_account*/
/*betting_sidebar*/
.betting_sidebar ul.nav {
  background: #262d50;
  border-radius: 4px;
}

.betting_sidebar ul.nav a {
  height: 40px;
  background: none !important;
  font-size: 12px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.betting_sidebar ul.nav a.active {
  color: #01BD35;
}

.abl_radios {
  margin: 5px 0;
  display: flex;
  justify-content: center;
}

.abl_radios label {
  color: #fff;
  text-transform: capitalize;
}

.abl_radios .custom-control {
  width: 30%;
}

.abl_radios [type=radio]:checked,
.abl_radios [type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.abl_radios [type=radio]:checked + label,
.abl_radios [type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
}

.abl_radios [type=radio]:checked + label:before,
.abl_radios [type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  border: 2px solid #01BD35;
  border-radius: 100%;
  background: none;
}

.abl_radios [type=radio]:not(:checked) + label:before {
  border: 2px solid #535776 !important;
}

.abl_radios [type=radio]:checked + label:after,
.abl_radios [type=radio]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #01BD35;
  position: absolute;
  top: 6px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.abl_radios [type=radio]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.abl_radios [type=radio]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.betting_sidebar {
  background: #191E38;
}

.betTable {
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
  color: #212529;
}

.betTable .row {
  margin: 0;
}

.betTable .row .col-7,
.betTable .row .col-5 {
  padding: 0;
  font-size: 12px;
  text-transform: capitalize;
}

.betTable .row span {
  text-transform: capitalize;
  padding: 4px 12px;
  display: inline-block;
}

.back .row span {
  background: #D5EDFF;
  border-radius: 100px;
}

.lay .row span {
  background: #FFDAF5;
  border-radius: 100px;
}

.text-right {
  text-align: right;
}

/*betting_sidebar*/
/*aside*/
.left_side_bar {
  background: #191E38;
  border: 1px solid #262D50;
  border-radius: 8px;
  padding: 0;
}

.content_area .col-md-2 {
  padding: 0;
}

.left_side_bar ul {
  margin: 0;
  padding: 10px;
}

.left_side_bar ul li {
  list-style: none;
}

.left_side_bar ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #0F1228;
  padding: 5px 8px;
  border-radius: 4px;
  color: #fff;
  text-transform: capitalize;
  font-size: 13px;
}

.left_side_bar ul li a:last-child {
  margin-bottom: 0;
}

.left_side_bar ul li a img {
  float: right;
}

.submenus {
  margin-top: 5px;
}

.sports_menus {
  background: #0F1228;
  padding: 5px 0;
  border-radius: 4px;
  margin-bottom: 5px;
  position: relative;
}

.left_side_bar ul .sports_menus a {
  display: flex;
  justify-content: initial;
  text-transform: capitalize;
  margin-bottom: 0;
}

.left_side_bar ul .submenus a img {
  margin-right: 3px;
}

.sports_menus div a {
  font-size: 12px !important;
  padding-left: 22px !important;
  padding-right: 0 !important;
}

.menu_close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.left_side_bar .nav-tabs {
  padding: 0;
  background: #262D50;
  border-bottom: none;
}

.left_side_bar .nav-tabs a.nav-link {
  font-size: 11px;
  text-align: center;
  padding: 10px 0;
  display: block;
  background: none !important;
  border: none !important;
  color: #fff;
}

.left_side_bar .nav-tabs a.nav-link.active {
  color: #03bd34;
  border-bottom: 2px solid #03bd34 !important;
  border-radius: 0;
}

.fade:not(.show).active {
  opacity: 1;
}

.sidebar_thumb {
  text-align: center;
  padding: 5px;
  width: 50%;
  display: inline-block;
}

.sidebar_thumb:nth-child(2n-1) {
  padding-left: 0;
}

.sidebar_thumb:nth-child(2n-0) {
  padding-right: 0;
}

.sidebar_thumb img {
  margin-bottom: 0;
  width: 100%;
  border-radius: 8px;
}

.dashboard_swiper .swiper-slide {
  position: relative;
}

.dashboard_swiper .swiper_content {
  position: absolute;
  top: 1%;
  left: 3%;
}

.dashboard_swiper .swiper_content ul li a {
  color: #fff;
  text-transform: capitalize;
  border-radius: 0;
  padding: 6px;
  font-size: 13px;
  box-shadow: inset 0 -2px 0 0 #fff;
}

.dashboard_swiper .swiper_content ul li a.active {
  background: #fff;
  color: #000;
}

.dashboard_swiper .swiper_content h3, .dashboard_swiper .swiper_content .h3 {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.dashboard_swiper .swiper_content p {
  background: #0F1228;
  border: 2px dashed #262D50;
  border-radius: 4px;
  margin: 5px 0;
  width: 60%;
  text-align: center;
  padding: 9px;
  color: #fff;
}

.dashboard_swiper .swiper_content a.playnow {
  background: #01BD35;
  border: 0.5px solid #00FF47;
  border-radius: 4px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  padding: 18px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}

/*aside*/
.footer {
  background: #191E38;
}

.row .footer_column {
  width: 33.333%;
  padding: 0 20px 20px;
}

.footer_column p {
  color: #E4E4E4;
  font-weight: 400;
  font-size: 13px;
}

.footer-bottom {
  padding: 10px 0;
  background: #0F1228;
}

.footer-bottom h4, .footer-bottom .h4 {
  color: #E4E4E4;
  font-weight: 500;
  font-size: 16px;
}

.footer-bottom p {
  color: #E4E4E4;
  font-weight: 400;
  font-size: 13px;
}

.slider1 .swiper-pagination-bullet.swiper-pagination-bullet-active,
.slider2 .swiper-pagination-bullet.swiper-pagination-bullet-active,
.slider3 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #03bd34;
}

.slider1 .swiper-pagination-bullet,
.slider2 .swiper-pagination-bullet,
.slider3 .swiper-pagination-bullet {
  background: #fff;
}

.slider2 .swiper-button-prev {
  left: inherit;
  bottom: 0;
  margin: 0;
  right: 70px;
  top: inherit;
  opacity: 1;
  color: #fff;
  margin-bottom: -13px;
}

.slider2 .swiper-button-next {
  bottom: 0;
  top: inherit;
  margin-bottom: -13px;
  color: #fff;
}

.slider2 .swiper-pagination {
  bottom: -1px;
  left: inherit !important;
  right: 30px !important;
  position: relative;
  text-align: right;
}

.slider3 .swiper-button-prev {
  left: inherit;
  bottom: 0;
  margin: 0;
  right: 7%;
  top: inherit;
  opacity: 1;
  color: #fff;
  margin-bottom: -13px;
}

.slider3 .swiper-button-next {
  bottom: 0;
  top: inherit;
  margin-bottom: -13px;
  color: #fff;
  right: 0;
}

.slider3 .swiper-pagination {
  bottom: -1px;
  left: inherit !important;
  right: 30px !important;
  position: relative;
  text-align: right;
}

.section-header h2, .section-header .h2 {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 0;
  text-transform: capitalize;
  color: #03bd34;
}

.hts {
  position: relative;
  padding: 80px 0;
}

.full-bg-img {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: auto;
  max-height: 100%;
}

.timeline .container {
  width: 68%;
  margin: 0 auto;
}

.timeline img {
  width: 41px;
}

.timeline h4, .timeline .h4 {
  text-transform: uppercase;
  color: #03bd34;
  font-size: 16px;
  margin: 10px 0;
  margin-bottom: 0;
}

.timeline p {
  font-size: 12px;
  font-weight: 200;
  color: #a1a1a1;
  margin: 0;
}

.timeline1 {
  width: 28%;
  padding-top: 110px;
  padding-left: 15px;
  position: relative;
}

.timeline1 span,
.timeline3 span {
  position: absolute;
  right: 10%;
  bottom: 12%;
}

.timeline2 {
  width: 28%;
  padding-top: 20px;
  padding-left: 15px;
  position: relative;
}

.timeline2 span {
  position: absolute;
  right: 10%;
  top: 10%;
}

.timeline3 {
  width: 28%;
  padding-top: 110px;
  padding-left: 15px;
  position: relative;
}

.timeline4 {
  position: absolute;
  right: 7%;
  top: -10%;
  width: 15%;
  text-align: right;
}

.timeline4 span {
  position: absolute;
  top: 20%;
  left: 20%;
}

.slider_games_section {
  position: relative;
  bottom: 0;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin-top: -35px;
  margin-bottom: 40px;
}

.slider_games_section img {
  width: 246px;
  margin: 0 5px;
}

.sports_area {
  display: flex;
}

.sa_col img {
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 5px;
}

.sports_area .sa_col:last-child {
  margin-right: 0;
}

.slide .carousel-indicators {
  right: 1%;
  left: inherit;
  margin-right: 15px;
  margin-bottom: -15px;
}

.slide .carousel-indicators button {
  width: 7px;
  height: 7px;
  border-radius: 30px !important;
  border: 1px solid transparent;
}

.slide a.carousel-control-next {
  bottom: -27px;
  top: inherit;
  right: 0;
  width: auto;
}

.slide a.carousel-control-prev {
  right: 6%;
  left: inherit;
  bottom: -27px;
  top: inherit;
  width: auto;
}

.carousel-indicators button.active {
  background: #01BD35;
}

.slide a.carousel-control-prev span, .slide a.carousel-control-next span {
  width: 20px;
}

.winner_titile h2, .winner_titile .h2 {
  color: #01BD35;
  display: flex;
  text-transform: capitalize;
  font-size: 85px;
  font-weight: 600;
  align-items: center;
}

.winner_titile h2 span em, .winner_titile .h2 span em {
  display: block;
  font-style: normal;
}

.winner_client img {
  margin-bottom: 5px;
  width: 50px;
}

.winner_client h5, .winner_client .h5 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #03bd34;
  text-transform: capitalize;
}

.spca,
.spca2 {
  padding: 50px 0;
}

.spca_div h2, .spca_div .h2 {
  font-size: 18px;
  color: #03bd34;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.spca_div img {
  display: block;
  margin: 0 0 auto auto;
}

.clientsay {
  margin-top: 20px;
  margin-bottom: 100px;
}

.client_say div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.client_say div h5 img, .client_say div .h5 img {
  width: 80px;
  display: block;
}

.client_say div img {
  width: 40px;
}

.social_cions {
  display: flex;
}

.social_cions a {
  display: block;
  margin-right: 5px;
}

.social_cions a img {
  width: 50px;
}

.second_foot {
  padding: 50px 20px;
}

.second_foot p {
  margin: 15px 0;
}

.btn_download {
  background: #01BD35;
  border: 1px solid #00FF47;
  border-radius: 4px;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  height: 55px;
}

.third_foot p {
  color: #01BD35;
  margin: 10px 0;
}

.third_foot em {
  font-style: normal;
  color: #E4E4E4;
  font-size: 11px;
  display: block;
  margin-top: 10px;
}

.first_foot button.btn_download {
  display: none;
}

@media (max-width: 767px) {
  .datepicker_input .kyc-dropdown, .datepicker_input .open_bet_row {
    margin: 0 0 10px;
    width: 100%;
    position: relative;
  }

  .sports_area {
    display: block;
  }

  .login-btn.btn-getstarted {
    display: block !important;
    width: unset !important;
  }

  .sa_col {
    width: 32.5%;
    display: inline-flex;
    margin: 0;
    margin-bottom: 5px;
  }

  .slider_games_section {
    margin: 20px 0;
    top: 15px;
  }

  .slide a.carousel-control-prev {
    right: inherit;
    left: 35%;
    bottom: -30px;
    z-index: 9;
  }

  .slide a.carousel-control-next {
    right: 35%;
    bottom: -30px;
    height: auto;
    z-index: 9;
  }

  .slide .carousel-indicators {
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -18px;
  }

  .hts {
    padding: 10px;
  }

  .section-header p {
    width: 90%;
    font-size: 11px;
  }

  .full-bg-img {
    display: none;
  }

  .hts .timeline .row:after {
    content: "";
    background: url(assets/images/line2.png);
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    background-size: contain;
  }

  .hts .timeline .row:before {
    content: "";
    background: url(assets/images/line1.png);
    position: absolute;
    width: 1px;
    height: 100%;
    right: 50%;
    background-size: contain;
  }

  .timeline {
    background: none;
    height: auto;
  }

  .timeline img {
    width: 41px;
    height: 41px;
    object-fit: contain;
  }

  .timeline p {
    font-size: 12px;
  }

  .timeline1,
.timeline2,
.timeline3,
.timeline4 {
    width: 50%;
    padding: 30px 10px;
    position: inherit;
    text-align: center;
  }

  .timeline .container {
    width: 100%;
  }

  .timeline1 span,
.timeline2 span,
.timeline3 span,
.timeline4 span {
    display: none;
  }

  .winner_titile h2, .winner_titile .h2 {
    font-size: 50px;
    justify-content: center;
  }

  .winner .text-light {
    text-align: center;
  }

  .winner_client {
    width: auto;
  }

  .order-xs-last {
    order: 2;
  }

  .order-xs-first {
    order: 1;
  }

  .spca_div {
    width: 90%;
  }

  .spca_div h2, .spca_div .h2 {
    font-size: 25px;
  }

  .client_say {
    padding: 10px;
  }

  .first_foot button.btn_download {
    display: inline-flex;
    width: auto;
    height: 42px;
    right: 0;
    padding: 5px 10px;
    margin-right: 15px;
    align-items: center;
  }
  .first_foot button.btn_download img {
    margin-right: 10px;
  }

  .clientsay {
    margin-bottom: 60px;
  }

  .first_foot button.btn_download img {
    width: 20px;
  }

  .first_foot img {
    width: 40%;
  }

  .third_foot button.btn_download {
    display: none;
  }

  .third_foot em {
    text-align: center;
  }

  .spca img {
    margin-bottom: 40px;
  }

  .btn-log-reg {
    cursor: pointer;
    display: block !important;
    width: 50%;
    margin-bottom: 10px;
    padding: 10px !important;
    margin-left: 20px;
    background: #01BD35;
    padding: 5px 14px;
    border-radius: 4px;
    transition: 0.3s;
    border: 0.5px solid #00FF47;
  }

  .navbar .btn-log-reg:hover {
    color: #000 !important;
  }

  .navbar .btn-log-reg img {
    width: 22px;
    transition: 0.9s;
  }

  .btn-log-reg:hover img {
    filter: invert(1);
  }

  .swiper.slider1 {
    padding-bottom: 40px;
  }

  .swiper.slider1,
.swiper.slider2,
.swiper.slider3 {
    /*    overflow: inherit;
      overflow-x: clip;*/
  }

  .slider1 .swiper-button-prev {
    left: 40%;
    margin-top: 22px;
    opacity: 1;
    color: #fff;
  }

  .slider1 .swiper-button-next {
    left: inherit;
    right: 40%;
    opacity: 1;
    bottom: 0;
    margin-top: 22px;
    color: #fff;
  }

  .slider2 .swiper-button-prev {
    left: 30%;
    margin-top: 70px;
    opacity: 1;
    color: #fff;
  }

  .slider2 .swiper-button-next {
    left: inherit;
    right: 30%;
    opacity: 1;
    bottom: 0;
    margin-top: 70px;
    color: #fff;
  }

  .slider2 .swiper-pagination {
    bottom: 5px;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    display: flex;
    justify-content: center;
  }

  .slider3 .swiper-button-prev {
    left: 35%;
    margin-top: 90px;
    opacity: 1;
    color: #fff;
  }

  .slider3 .swiper-button-next {
    left: inherit;
    right: 35%;
    opacity: 1;
    margin-top: 90px;
    color: #fff;
  }

  .slider3 .swiper-pagination {
    bottom: 7px;
    position: absolute;
    right: 0 !important;
    left: 0 !important;
    justify-content: center !important;
    display: flex;
  }

  .winner_section {
    padding: 40px 0;
  }

  .spca, .spca2 {
    padding: 10px 0;
  }
}
.client-slider .swiper-slide {
  width: 160px;
}

.swiper.slider2.client-slider, .testimonial-slider {
  padding-bottom: 25px;
}

.testimonial-slider .swiper-slide {
  width: calc(33% - 10px);
  margin-right: 10px;
}

.nav.dashboard_navpills {
  margin-bottom: 10px;
}

.carousel-item img {
  height: 250px;
  border-radius: 12px;
  width: 100%;
}

.exposure-list .bet-list {
  padding-top: 30px;
}

.placebet.back-bg {
  background: #83B9EA !important;
}

.stakes button {
  margin: 8px 2px 0;
}

.suspend-runner.suspended {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
}
.suspend-runner.suspended::after {
  display: none;
}
.suspend-runner.suspended .suspend-cont {
  color: #191E38;
  background: #D4EDFF;
}

.placebet.lay-bg {
  background: #EEADBA !important;
}

.modal .modal-content {
  background: #262D50;
  border-radius: 8px;
}
.modal .modal-content .modal-header {
  border: none;
}

.bankList-table {
  overflow: auto;
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .carousel-item img {
    height: 90px !important;
  }

  .slider_games_section img {
    width: 194px;
    height: 50px;
    margin: 0 5px;
  }

  .testimonial-slider .swiper-slide {
    width: 100%;
    margin: unset;
  }

  .header {
    position: fixed;
    padding: 0;
  }

  div#HomeSlider {
    margin-top: 52px;
  }

  .casino_slider {
    display: block;
  }

  .casino_slider img {
    margin-bottom: 15px;
  }

  .dashboard_swiper .swiper_content p {
    display: inline;
    padding: 5px;
    font-size: 12px;
  }

  .dashboard_swiper .swiper_content a.playnow {
    padding: 5px;
    height: auto;
    line-height: normal;
    font-size: 12px;
  }

  .dashboard_swiper .swiper_content h3, .dashboard_swiper .swiper_content .h3 {
    font-size: 25px;
    margin: 5px 0;
  }

  .dashboard_swiper {
    margin: 20px 0;
  }

  .dashboard_swiper .swiper_content {
    top: 5%;
    left: 5%;
  }

  .header {
    padding: 0 !important;
  }

  .main_navbar,
.left_side_bar {
    border-radius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
  }

  .mo_header_1x1 {
    display: inline-block;
  }

  .left_side_bar .nav-tabs,
.left_side_bar .tab-content,
.input-calednar,
.header_1x1 {
    display: none;
  }

  .dashboard_navpills li {
    display: inline-block;
  }

  .event_lists {
    padding: 0;
    margin-bottom: 5px;
  }

  .dashboard_tabcontent .card-header {
    padding: 5px 10px;
  }

  .dashboard_tabcontent .card-header a img {
    height: 40px;
  }

  .dashboard_navpills {
    display: block !important;
    white-space: nowrap;
    overflow-x: auto;
  }

  ul.dashboard_navpills li a {
    margin-right: 5px;
  }

  .event_lists button {
    padding: 5px;
  }

  .oods_boxes button {
    width: 49%;
    vertical-align: middle;
  }

  .dashboard_list {
    padding: 5px;
  }

  .h1x1 {
    width: 75%;
    margin: auto 0 auto auto;
  }

  .dashboard_list .col-md-6,
.dashboard_list .col-3,
.dashboard_list .col-9 {
    padding: 0 1px;
  }

  .sports_area {
    display: block;
  }

  .left_side_bar ul li a {
    padding: 10px 8px;
  }

  .scoarecard {
    padding: 10px 0;
    margin-top: 15px;
    position: relative;
  }

  .score h3, .score .h3 {
    font-size: 18px;
    position: absolute;
    right: 5px;
    top: 60px;
  }

  .event_lists p {
    font-size: 16px;
  }

  .event_lists em {
    margin-left: 0;
  }

  .event_lists p img {
    display: none;
  }

  .event_lists div {
    margin-left: 0;
  }

  .conetnt_col {
    padding: 0 !important;
  }

  .scoarecard .row {
    margin: 0;
  }

  .detail_data .nav-pills {
    width: 95%;
    margin: 0 auto;
    display: flex;
  }

  .score {
    text-align: left;
  }

  .score p em {
    position: absolute;
    right: 0px;
    top: 5px;
  }

  .market .card-header span {
    /*width: 60%;*/
    text-align: right;
  }

  .market .card-header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .market .card-header span img {
    margin: 0 0 0 10px;
  }

  .odds_section .back1,
.odds_section .lay1 {
    display: none;
  }

  .odds_section button {
    width: 49%;
  }

  .fancy_market .event_name {
    font-size: 12px;
  }

  .market .card-body .row {
    padding: 1px 2px;
  }

  .fancy_market .bet {
    padding: 2px 8px;
  }

  .placebet > div {
    padding: 0 5px !important;
  }
  .placebet > div .form-group {
    display: flex;
    flex-wrap: wrap;
  }

  .ccp_btns {
    margin-bottom: 5px;
  }

  .stakes {
    display: inline-block;
    padding: 0 5px !important;
  }

  .stakes button {
    width: calc(20% - 4px);
    margin: 8px 2px 0;
  }

  .ccp_btns,
.placebet p {
    text-align: left;
  }

  .betting_sidebar {
    margin-top: 10px;
  }

  .market .card-body {
    padding-bottom: 3px;
  }

  .cm9 p span {
    font-size: 20px;
  }

  .cm9 p em {
    font-size: 30px;
  }

  .cm9 table tfoot td {
    font-size: 18px;
  }

  .profile_account ul {
    white-space: nowrap;
    display: block;
    overflow-x: auto;
    margin-top: 10px;
  }

  .profile_account ul li {
    display: inline-block;
  }

  .bstd,
.bank_aba,
.sw_history {
    margin-bottom: 5px;
  }

  .table_theme tbody tr td {
    white-space: nowrap;
  }

  .withdrawal_div form {
    width: 95%;
    margin-bottom: 10px;
  }

  .ex_tabs ul.nav-pills {
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
  }

  .ex_tabs ul.nav-pills li {
    display: inline-block;
  }

  .ex_tabs ul.nav-pills li a {
    width: 120px;
  }

  .sa_col {
    width: 32.5%;
    display: inline-flex;
    margin: 0;
    margin-bottom: 5px;
  }

  .slider_games_section {
    margin: 20px 0;
    top: 15px;
  }

  .slide a.carousel-control-prev {
    right: inherit;
    left: 35%;
    bottom: -30px;
    z-index: 9;
  }

  .slide a.carousel-control-next {
    right: 35%;
    bottom: -30px;
    height: auto;
    z-index: 9;
  }

  .slide .carousel-indicators {
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -18px;
  }

  .searchbar_input {
    display: none;
  }

  .hts {
    padding: 10px;
  }

  .section-header p {
    width: 90%;
    font-size: 11px;
  }

  .full-bg-img {
    display: none;
  }

  .hts .timeline .row:after {
    content: "";
    background: url(assets/images/line2.png);
    position: absolute;
    width: 100%;
    height: 1px;
    top: 50%;
    background-size: contain;
  }

  .hts .timeline .row:before {
    content: "";
    background: url(assets/images/line1.png);
    position: absolute;
    width: 1px;
    height: 100%;
    right: 50%;
    background-size: contain;
  }

  .timeline {
    background: none;
    height: auto;
  }

  .timeline img {
    width: 41px;
    height: 41px;
    object-fit: contain;
  }

  .timeline p {
    font-size: 12px;
  }

  .timeline1,
.timeline2,
.timeline3,
.timeline4 {
    width: 50%;
    padding: 30px 10px;
    position: inherit;
    text-align: center;
  }

  .timeline .container {
    width: 100%;
  }

  .timeline1 span,
.timeline2 span,
.timeline3 span,
.timeline4 span {
    display: none;
  }

  .winner_titile h2, .winner_titile .h2 {
    font-size: 50px;
    justify-content: center;
  }

  .winner .text-light {
    text-align: center;
  }

  .winner_titile h2 span, .winner_titile .h2 span {
    font-size: 30px;
  }

  .winner_client {
    width: auto;
  }

  .order-xs-last {
    order: 2;
  }

  .order-xs-first {
    order: 1;
  }

  .spca_div {
    width: 90%;
  }

  .spca_div h2, .spca_div .h2 {
    font-size: 25px;
  }

  .client_say {
    padding: 10px;
  }

  .footer_column {
    width: 100% !important;
  }

  .content_area {
    padding-top: 23px;
  }

  .first_foot button.btn_download {
    display: inline-flex;
    width: auto;
    height: 35px;
    right: 15px;
    margin-right: 15px;
    align-items: center;
  }

  .first_foot button.btn_download img {
    width: 20px;
  }

  .footer_column {
    padding: 10px;
  }

  .first_foot img {
    width: 40%;
  }

  .third_foot button.btn_download {
    display: none;
  }

  .third_foot em {
    text-align: center;
  }

  .footer-bottom h4, .footer-bottom .h4 {
    margin-top: 10px;
  }

  .section-header h2, .section-header .h2 {
    font-size: 25px;
  }

  .spca img {
    margin-bottom: 40px;
  }

  .btn-log-reg {
    cursor: pointer;
    display: block !important;
    width: 50%;
    margin-bottom: 10px;
    padding: 10px !important;
    margin-left: 20px;
    background: #01BD35;
    padding: 5px 14px;
    border-radius: 4px;
    transition: 0.3s;
    border: 0.5px solid #00FF47;
  }

  .navbar .btn-log-reg:hover {
    color: #000 !important;
  }

  .navbar .btn-log-reg img {
    width: 22px;
    transition: 0.9s;
  }

  .btn-log-reg:hover img {
    filter: invert(1);
  }

  .swiper.slider1 {
    padding-bottom: 40px;
  }

  .swiper.slider1,
.swiper.slider2,
.swiper.slider3 {
    /*    overflow: inherit;
        overflow-x: clip;*/
  }

  .slider1 .swiper-button-prev {
    left: 40%;
    margin-top: 22px;
    opacity: 1;
    color: #fff;
  }

  .slider1 .swiper-button-next {
    left: inherit;
    right: 40%;
    opacity: 1;
    bottom: 0;
    margin-top: 22px;
    color: #fff;
  }

  .slider2 .swiper-button-prev {
    left: 30%;
    margin-top: 70px;
    opacity: 1;
    color: #fff;
  }

  .slider2 .swiper-button-next {
    left: inherit;
    right: 30%;
    opacity: 1;
    bottom: 0;
    margin-top: 70px;
    color: #fff;
  }

  .slider2 .swiper-pagination {
    bottom: 5px;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    display: flex;
    justify-content: center;
  }

  .slider3 .swiper-button-prev {
    left: 35%;
    margin-top: 90px;
    opacity: 1;
    color: #fff;
  }

  .slider3 .swiper-button-next {
    left: inherit;
    right: 35%;
    opacity: 1;
    margin-top: 90px;
    color: #fff;
  }

  .slider3 .swiper-pagination {
    bottom: 7px;
    position: absolute;
    right: 0 !important;
    left: 0 !important;
    justify-content: center !important;
    display: flex;
  }

  .winner_section {
    padding: 40px 0;
  }

  .spca, .spca2 {
    padding: 10px 0;
  }

  .model_log_signup .row .col-md-7 {
    display: none;
  }

  .popup_form {
    left: 0;
  }

  .pupup_signup {
    border-radius: 0px 0px 7px 7px;
  }

  .form_ui {
    border-radius: 7px 7px 0 0;
  }

  .model_log_signup .modal-body {
    position: relative;
  }

  .model_log_signup .modal-body .btn-close {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9;
    filter: inherit;
    font-size: 12px;
  }

  #LoginPopup, #SignupPopup {
    z-index: 9999;
  }
}
.bottom_tabs ul li a.dropdown-toggle:after {
  display: none;
}

.dark-mode {
  cursor: pointer;
}

.bottom_tabs ul li a img.lightmodeimg {
  display: none;
}

.light_mode_on .bottom_tabs ul li a img.lightmodeimg {
  display: block;
}

ng-http-loader #spinner.backdrop {
  background-color: rgba(0, 0, 0, 0.2) !important;
  opacity: 1 !important;
}
ng-http-loader #spinner.backdrop .sk-wave .sk-rect {
  background-color: #000;
}

.col-md-9.conetnt_col {
  margin-top: 30px;
}